import React, {useEffect, useState} from 'react';
import classes from "./LivenessStatus.module.css";
import Button from "../../../components/Button/Button";
import Layout from "../../../components/Layout/Layout";
import * as RoutesName from "../../../routes/routes";
import useAuth from "../../../Hooks/useAuth";
import {images} from "../../../assets/images/images";
import {useLocation, useNavigate} from "react-router-dom";

const LivenessStatus = () => {

    const navigate = useNavigate();


    const {auth, setAuth} = useAuth();



    const [liveness, setLivness] = useState(false);

    useEffect(()=>{
        if (auth.successActionsList.length > 2 ) {
           setLivness(true)
        }
        if (auth.failedActionsList.length > 1) {
           setLivness(false)
        }
    }, [])

    const backgroundBar = (liveness) => {
        if (liveness) {
            return {
                background: `linear-gradient(#ffffff00 0%, #ffffff00 30%,  #0da64596 100%)`,
            };
        }
        if (!liveness) {
            return {
                background: `linear-gradient(#ffffff00 0%, #ffffff00 50%,  #ca201599 100%)`,
            };
        }
    }



    const submit = async (e) => {
        e.preventDefault();

        if (liveness) {
            navigate(RoutesName.UserVideo)
        }
        if (!liveness) {
            navigate(RoutesName.Liveness)
        }
        //

    }

    return (
        <Layout>
            <form onSubmit={(e) => submit(e)} className={`${classes.container} column jc-around ai-center height-100 py-3 px-2`}>
                {/*<HeaderBuilder step="وضعیت LivenessNew" title=""/>*/}

                <img
                    src={liveness ? images.check : images.close}
                    className={`${liveness ? classes.check : classes.close} ${classes.icon}`}
                />
          {/*      <p>{JSON.stringify(auth.successActionsList)}</p>
                <p>{JSON.stringify(auth.failedActionsList)}</p>
                <p>{JSON.stringify(auth.frame2)}</p>
                <p>{JSON.stringify(auth.frame1)}</p>*/}

                <span className={`${liveness ? 'text-green' : 'text-red'} fs-01`}>{liveness ? "عملیات Liveness با موفقیت انجام شد." : "عملیات Liveness انجام نشد."}</span>

                <Button
                    buttonClass={`${classes.thisButton} cursor-pointer`}
                    type="submit"
                    buttonTitle={liveness ?  "شروع فیلم‌برداری" : "تلاش دوباره"}
                />

            </form>
        </Layout>
    );
};

export default LivenessStatus;
