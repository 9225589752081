import React, {useEffect, useState} from 'react';
import classes from './UploadFiles.module.css'
import HeaderBuilder from "../../../components/HeaderBuilder/HeaderBuilder";
import {images} from "../../../assets/images/images";
import Layout from "../../../components/Layout/Layout";
import {useNavigate} from "react-router-dom";
import {createFile, dataURItoBlob, generateMD5} from "../../../utils/generateMD5";
import {query, requestPayment, submit} from "../../../api/auth";
import toast from "react-hot-toast";
import * as RoutesName from "../../../routes/routes";
import Button from "../../../components/Button/Button";
import useAuth from "../../../Hooks/useAuth";
import Icon from "../../../components/Icon/Icon";
import axios from "axios";
import {Result} from "../../../routes/routes";

const UploadFiles = () => {

    const navigate = useNavigate();

    const [sending, setSending] = useState(true)


    const {auth, setAuth} = useAuth();


    useEffect(() => {
        // setTimeout(() => navigate(RoutesName.Agreement) , 70000);

        uploadFileFunc().then(() => {
            // navigate(RoutesName.Agreement)
        })


    }, [])

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }



    const uploadFileFunc = async () => {
        let newAuth = {...auth}
        setSending(true)
        try {
            const requestPaymentFunc = await requestPayment(auth.processId, auth.completeOtp_accessToken, auth.completeOtp_nextStepToken)

            await sleep(2000)

            try {
                const selfiSrcBlob = await axios({
                    method: "get",
                    url: auth.selfi,
                    responseType: "blob",
                })
                const selfiSrcMD5 = await generateMD5(selfiSrcBlob.data)
                const selfiFile = await createFile(selfiSrcBlob.data, selfiSrcMD5)

                const frame1SrcBlob = await axios({
                    method: "get",
                    url: auth.frame1,
                    responseType: "blob",
                })
                const frame1SrcMD5 = await generateMD5(frame1SrcBlob.data)
                const frame1File = await createFile(frame1SrcBlob.data, frame1SrcMD5)

                const frame2SrcBlob = await axios({
                    method: "get",
                    url: auth.frame2,
                    responseType: "blob",
                })
                const frame2SrcMD5 = await generateMD5(frame2SrcBlob.data)
                const frame2File = await createFile(frame2SrcBlob.data, frame2SrcMD5)

                const submitFunc = await submit(selfiFile, frame1File, frame2File, auth.processId, auth.completeOtp_kycFollowupCode, auth.requestId, auth.completeOtp_accessToken, requestPaymentFunc.data.nextStepToken)

                let requestId = auth.requestId + 1

                const func = async (requestId)=>{

                    for (let i=0 ; i< 100; i++) {
                        const queryFunc = await query(auth.processId, auth.completeOtp_kycFollowupCode, requestId, auth.completeOtp_accessToken, submitFunc.data.nextStepToken)
                        requestId = requestId + 1;
                        if (queryFunc?.data?.status === "finished" && queryFunc?.data?.result) {

                            newAuth = {
                                ...newAuth,
                                queryResult: queryFunc.data.result,
                                query_nextStepToken: queryFunc.data.nextStepToken,
                                requestId: requestId
                            }

                            navigate(RoutesName.Agreement)


                            break;
                        }
                        if (queryFunc?.data?.status === "finished" && !(queryFunc?.data?.result)) {

                            newAuth = {
                                ...newAuth,
                                queryResult: queryFunc.data.result,
                                query_nextStepToken: queryFunc.data.nextStepToken,
                                requestId: requestId
                            }
                            navigate(RoutesName.Result)


                            break;
                        }



                        await sleep(1500)
                    }
                }

                await func(requestId)






            } catch (submitFunc) {

                newAuth = {
                    ...newAuth,
                    requestId: auth.requestId + 1
                }
                setSending(false)

                if (submitFunc.response.status !== 200) {
                    toast.error(submitFunc.response.data.message ? submitFunc.response.data.message : "خطا در سرور!")
                    //return false;
                }
            }


        } catch (requestPaymentFunc) {
            setSending(false)
            if (requestPaymentFunc.response.status !== 200) {
                toast.error(requestPaymentFunc.response.data.message ? requestPaymentFunc.response.data.message : "خطا در سرور!")
                //return false;
            }
        }
        setAuth(newAuth)
    }

    return (
        <Layout>
            <div className={`${classes.container} column jc-between ai-center height-100 py-3 px-2`}>
                <HeaderBuilder step="گام 7" title="ارسال اطلاعات"/>
                {sending ? <>
                        <img src={images.uploadFile} className={`${classes.image} rounded-8`} alt=""/>
                        <span>در حال ارسال اطلاعات ...</span>
                    </> :
                    <>

                        <span className={`text-red`}>خطا در ارسال اطلاعات، دوباره تلاش کنید!</span>

                        <Button
                            buttonClass={`${classes.thisButton} cursor-pointer`}
                            type="submit"
                            buttonTitle="تلاش دوباره"
                            onClick={() => uploadFileFunc()}
                        />
                    </>


                }
            </div>
        </Layout>
    );
};

export default UploadFiles;



