import React from 'react';
import classes from './TextInput.module.css'
import Icon from "../Icon/Icon";

const TextInput = ({inputClass, onchange, label, value, alerts, ...props }) => {
    return (
       <div className={`${inputClass}`}>
           <div className={`${classes.inputGroup} ${alerts.length !== 0 && classes.hasError}`}>
               <input
                   onChange={onchange}
                   value={value}
                   {...props}
               />
               <label className={value && classes.hasValue}>{label}</label>
               {alerts.length !== 0 && <Icon iconName="icon-info-circled flex fs-05 text-red" iconClass={`${classes.thisIcon}`}/>}
           </div>
           <div className={`column fs-0-8 text-red mt-1`}>
               {alerts.map((alert, index) => <span key={index} className={`pr-05 `}>{alert}</span>)}
           </div>
       </div>


    );
};

export default TextInput;
