import React from 'react';
import {Route, Routes} from "react-router-dom";
import AnimationLayout from "../components/AnimationLayout/AnimationLayout";
import * as RoutesName from "../../src/routes/routes";
import Unauthorized from "../components/AnimationLayout/components/Unauthorized/Unauthorized";
import Login from "./pages/Login/Login";
import OTP from "./pages/OTP/OTP";
import NationalCode from "./pages/NationalCode/NationalCode";
import UserImageHelp from "./pages/UserImageHelp/UserImageHelp";
import UserImage from "./pages/UserImage/UserImage";
import UserImagePreview from "./pages/UserImagePreview/UserImagePreview";
import LivenessHelp from "./pages/LivenessHelp/LivenessHelp";
import Liveness from "./pages/Liveness/Liveness";
import LivenessStatus from "./pages/LivenessStatus/LivenessStatus";
import UserVideo from "./pages/UserVideo/UserVideo";
import UserVideoPreview from "./pages/UserVideoPreview/UserVideoPreview";
import UserSignatureHelp from "./pages/UserSignatureHelp/UserSignatureHelp";
import UserSignature from "./pages/UserSignature/UserSignature";
import UserSignaturePreview from "./pages/UserSignaturePreview/UserSignaturePreview";
import UploadFiles from "./pages/UploadFiles/UploadFiles";
import Agreement from "./pages/Agreement/Agreement";
import Result from "./pages/Result/Result";
import LivenessNew from "./pages/Liveness-new/Liveness-new";
import RequireAuth from "../components/RequireAuth/RequireAuth";
import Missing from "../components/Layout/Missing/Missing";

const Main = () => {
    return (
        <Routes>
            <Route element={<AnimationLayout/>}>
                <Route path={RoutesName.Login} element={<Login/>}/>
                <Route element={<RequireAuth/>}>
                    <Route path={RoutesName.OTP} element={<OTP/>}/>
                    <Route path={RoutesName.NationalCode} element={<NationalCode/>}/>
                    <Route path={RoutesName.UserImageHelp} element={<UserImageHelp/>}/>
                    <Route path={RoutesName.UserImage} element={<UserImage/>}/>
                    <Route path={RoutesName.UserImagePreview} element={<UserImagePreview/>}/>
                    <Route path={RoutesName.LivenessHelp} element={<LivenessHelp/>}/>
                    <Route path={RoutesName.Liveness} element={<LivenessNew/>}/>
                    <Route path={RoutesName.LivenessStatus} element={<LivenessStatus/>}/>
                    <Route path={RoutesName.UserVideo} element={<UserVideo/>}/>
                    <Route path={RoutesName.UserVideoPreview} element={<UserVideoPreview/>}/>
                    <Route path={RoutesName.UserSignatureHelp} element={<UserSignatureHelp/>}/>
                    <Route path={RoutesName.UserSignature} element={<UserSignature/>}/>
                    <Route path={RoutesName.UserSignaturePreview} element={<UserSignaturePreview/>}/>
                    <Route path={RoutesName.UploadFiles} element={<UploadFiles/>}/>
                    <Route path={RoutesName.Agreement} element={<Agreement/>}/>
                    <Route path={RoutesName.Result} element={<Result/>}/>
                </Route>
                <Route path="unauthorized" element={<Unauthorized/>}/>
            </Route>
            {/* 404 Not Found */}
            <Route path="*" element={<Missing/>}/>
        </Routes>
    );
};

export default Main;
