import React, {useRef, useState} from 'react';
import classes from './OTP.module.css'
import Layout from "../../../components/Layout/Layout";
import {useLocation, useNavigate} from "react-router-dom";
import Button from "../../../components/Button/Button";
import * as RoutesName from "../../../routes/routes";
import OtpInput from "react-otp-input";
import {agahInquiry, captcha, validateOtp} from "../../../api/auth";
import toast from "react-hot-toast";
import Loading from "../../../components/Loading/Loading";
import useAuth from "../../../Hooks/useAuth";
import Countdown from "react-countdown";
import Icon from "../../../components/Icon/Icon";
import TextInput from "../../../components/TextInput/TextInput";
import {isValidNationalCode} from "../../../utils/utils";

const OTP = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const {auth, setAuth} = useAuth();
    const[loading, setLoading]= useState(false)

    const[needCaptcha, setNeedCaptcha]= useState(false);
    const [otp, setOTP] = useState({value: "", error: []});
    const[captchaSrc, setCaptchaSrc]= useState(null);
    const[startTimer, setStartTime]= useState(true);
    const[captchaInput, setCaptchaInput]= useState({value: "", error: [], nameFa: "Captcha"});
    const[ratelimit, setRatelimit]= useState({
        status: false,
        time: 0,
    });

    const refresh = useRef(false)

    const prevStepData = location.state?.res || "";

    const agahInquiryUrl = `/kyc/process/v2/${auth.processId}/agah_inquiry`;
    const validateOtpUrl = `/kyc/process/v2/${auth.processId}/validate_otp`;


    const captchaFunction = async (refr , url) => {

        try {
            const captchaFunc = await captcha(auth.processId, refr, url, prevStepData.accessToken, )
            //console.log("captchaFunc", captchaFunc)
            const blob = new Blob([captchaFunc.data]);
            setCaptchaSrc(URL.createObjectURL(blob))

        } catch (captchaFunc) {
            //console.log(captchaFunc)
            if (captchaFunc.response.status !== 200) {
                toast.error(captchaFunc.response.data.message ? captchaFunc.response.data.message : "خطا در سرور!")
                //return false;
            }
        }
    }

    const sendOTP = async (newAuth) => {
        const validateOtpData = {
            requestId: auth.requestId,
            otpFollowupCode: prevStepData.otpFollowupCode,
            otpCode: otp.value,
        }
        try {
            const validateOtpFunc = await validateOtp(prevStepData.accessToken, prevStepData.processId, prevStepData.nextStepToken ,validateOtpData, `${needCaptcha && captchaInput.value}`)
            const nextStepData = {
                nextStepToken: validateOtpFunc.data.nextStepToken,
                accessToken: prevStepData.accessToken,
                processId: prevStepData.processId,
                otpFollowupCode: prevStepData.otpFollowupCode,
            }
            navigate(RoutesName.NationalCode, {state: {res: nextStepData && nextStepData }})
            newAuth = {
                ...newAuth,
                requestId: auth.requestId + 1
            }
        } catch (validateOtpFunc) {
            newAuth = {
                ...newAuth,
                requestId: auth.requestId + 1
            }

            setNeedCaptcha(true)
            // here

            /* refresh.current = true*/

            captchaFunction(refresh.current = false, validateOtpUrl)

            console.log("validateOtpFunc", validateOtpFunc)

            if (validateOtpFunc.response.status !== 200) {
                toast.error(validateOtpFunc.response.data.message ? validateOtpFunc.response.data.message : "خطا در سرور!")
                //return false;
            }
            if (validateOtpFunc.response.status === 429) {
                toast.error(validateOtpFunc.response.data.message ? validateOtpFunc.response.data.message : "خطا در سرور!")
                setRatelimit({
                    status: true,
                    time: parseInt(validateOtpFunc?.response?.headers['x-ratelimit-reset']),
                })
            }

        }
        setAuth(newAuth)
    }


    const resendOtp =  async () => {

        setLoading(true)

        if (startTimer) return setLoading(false);

        if (needCaptcha && captchaInput.value <= 0) {
            toast.error("Captcha را واردکنید.")
            //setOTP({...otp , error: ["Captcha را واردکنید."]})
            //setCaptchaInput({...captchaInput , error: ["Captcha را واردکنید."]})
            return setLoading(false);;
        }

        let newAuth = {...auth}

        const agahInquiryData = {
            requestId: auth.requestId,
            nationalCode: auth.nationalCode,
            sejamCode: auth.sejamCode,
        }
        try {

           /* const agahInquiryFunc = await agahInquiry(prevStepData.accessToken, prevStepData.processId, prevStepData.nextStepToken, agahInquiryData, `${needCaptcha && loginInput.captcha.value}`)*/

            const agahInquiryFunc = await agahInquiry(prevStepData.accessToken, prevStepData.processId, prevStepData.nextStepTokenPrev, agahInquiryData, `${needCaptcha && captchaInput.value}`)
            console.log("agahInquiryFunc", agahInquiryFunc)
            toast.success("کد 5 رقمی به شماره ثبت شده شما در سامانه سجام ارسال شد")
            newAuth = {
                ...newAuth,
                requestId: auth.requestId + 1
            }

        } catch (agahInquiryFunc) {

            setNeedCaptcha(true)
            // here

           /* refresh.current = true*/

            captchaFunction(refresh.current = false, agahInquiryUrl)

            newAuth = {
                ...newAuth,
                requestId: auth.requestId + 1
            }

            if (agahInquiryFunc.response.status !== 200) {
                toast.error(agahInquiryFunc.response.data.message ? agahInquiryFunc.response.data.message : "خطا در سرور!")
                //return false;
            }
            if (agahInquiryFunc.response.status === 429) {
                toast.error(agahInquiryFunc.response.data.message ? agahInquiryFunc.response.data.message : "خطا در سرور!")
                setRatelimit({
                    status: true,
                    time: parseInt(agahInquiryFunc?.response?.headers['x-ratelimit-reset']),
                })
            }
        }
        setStartTime(true)
        setLoading(false)
        setAuth(newAuth)
    }




    const handleComplete = () => {
        setStartTime(false)

    }

   /* const inputHandler = (e) => {
        // setloginInputStatus(null)
        let errorMessage = []
        let inputVal = e.target.value
        if (typeof e.target.dataset?.min && inputVal.length < e.target.dataset.min) {
            errorMessage.push(`${e.target.dataset.namefa} حداقل باید ${e.target.dataset.min} کاراکتر باشد.`)
        }
        if (typeof e.target.dataset?.max && inputVal.length >= e.target.dataset.max) {
            errorMessage.push(`${e.target.dataset.namefa} حداکثر باید ${e.target.dataset.max - 1} کاراکتر باشد.`)
        }


        let prevState = {
            ...captchaInput,
            [e.target.dataset.name]: {...captchaInput[e.target.dataset.name], value: inputVal, error: errorMessage}
        }

        setCaptchaInput(prevState)
    }*/

    /*const isFormValid = () => {
        let inputs = {...captchaInput}

        const hasError = Object.values(captchaInput).find(input => input.error.length > 0)
        if (hasError) return false
        let isEmpty = false
        for (const key in inputs) {

            if (key === 'captcha' && !needCaptcha) {
                continue;
            }

            if (inputs[key].value.length === 0) {
                isEmpty = true
                inputs = {
                    ...inputs,
                    [key]: {
                        ...inputs[key],
                        error: [`وارد کردن فیلد ${inputs[key].nameFa} الزامی است.`]
                    }
                }
            }
        }
        setCaptchaInput(inputs);
        return !isEmpty;
    }*/

    const submit = async (e) => {
        e.preventDefault();
        let newAuth = {...auth}
        if (!otp.value) {
            return setOTP({...otp, error: ["کد را وارد کنید!"]})
        }

        if (needCaptcha && captchaInput.value <= 0) {
            toast.error("Captcha را واردکنید.")
            //return setCaptchaInput({...captchaInput , error: ["Captcha را واردکنید."]})
             //setOTP({...otp , error: ["Captcha را واردکنید."]})
            return false;
        }


       // if (!isFormValid()) return false;

        setLoading(true)
        /*newAuth = {
            ...newAuth,
            nationalCode: loginInput.nationalCode.value,
        }*/
        sendOTP(newAuth).then(()=>setLoading(false))
        //navigate(RoutesName.NationalCode)
    }

    const buttonTitleHandler = () => {
        return <div className={`row jc-center ai-center ${ratelimit.status && 'ml-025'}`}>
            {ratelimit.status &&
                <span className={`mr-025`}>
                <Countdown
                    onStart={()=>ratelimit.status}
                    date={ Date.now() + ratelimit.time*1000 }
                    renderer={props => <div className={` direction-ltr`}> ({props.minutes}:{props.seconds}) </div>}
                    onComplete={() => setRatelimit({
                        status: false,
                        time: 0,
                    })}
                />
            </span>
            }
            <span>ثبت</span>
        </div>
    }


    return (
        <Layout>
            <form onSubmit={(e) => submit(e)} className={`${classes.container} column jc-around ai-center height-100 py-2 px-4 ${loading && 'blur-2'}`}>

                <span className={``}>کد 5 رقمی به شماره ثبت شده شما در سامانه سجام ارسال شد. این کد را وارد نمایید.</span>

                <OtpInput
                    value={otp.value}
                    onChange={(e) => setOTP({...otp, value: e})}
                    numInputs={5}
                    separator={<span> - </span>}
                    containerStyle={`direction-ltr ${classes.otpInputContainer}`}
                    inputStyle={`${classes.otpInput}`}
                    shouldAutoFocus="true"
                    isInputNum="true"
                />

                <div className={`fs-0-9 flex row    ${startTimer ? 'text-gray cursor-not-allowed' : 'cursor-pointer hover-text'}`} onClick={()=>resendOtp()}><span className={`ml-025`}>ارسال مجدد کد</span>   { startTimer &&<span className={`mr-025`}>

                    <Countdown

                        onStart={()=>startTimer}
                        date={ Date.now() + 180000 }
                        renderer={props => <div className={` direction-ltr`}> ({props.minutes} : {props.seconds}) </div>}
                        onComplete={() => handleComplete()}
                    />

                </span>}</div>





                {needCaptcha &&
                    <div className={`column jc-center ai-center mb-1 mt-2 width-100`}>

                        <div className={`row jc-center ai-center mb-1`}>
                            <img src={captchaSrc} alt="" className={`${classes.captcha}  ml-05`}/>
                            <Icon
                                iconName="icon-arrows-ccw flex fs-07"
                                iconClass={`${classes.thisIcon} hover-text cursor-pointer mr-05`}
                                onClick={()=>captchaFunction(refresh.current = true, agahInquiryUrl)}
                            />
                        </div>
                        <TextInput
                            value={captchaInput.value}
                            type="text"
                            label="Captcha"
                            data-name="captcha"
                            data-namefa="Captcha"
                            data-type="input"
                            data-min={6}
                            maxLength="6"
                            onchange={(e) => setCaptchaInput({...captchaInput, value: e.target.value}) }
                            alerts={captchaInput.error}
                            inputClass={`width-100 mt-1`}
                        />
                    </div>
                }

                {otp.error.length !== 0 && <span className={`text-red`}>{otp.error}</span>}



                <Button
                    buttonClass={`${classes.thisButton} cursor-pointer`}
                    type="submit"
                    disabled={otp.value.length < 5 || ratelimit.status}

                    buttonTitle={buttonTitleHandler()}
                />


            </form>
            {loading && <Loading/>}
        </Layout>
    );
};

export default OTP;
