import React, {useCallback, useEffect, useRef, useState} from 'react';
import classes from './UserVideo.module.css'
import Webcam from "react-webcam";
import Countdown from "react-countdown";
import {images} from "../../../assets/images/images";
import Button from "../../../components/Button/Button";
import Layout from "../../../components/Layout/Layout";
import * as RoutesName from "../../../routes/routes";
import {useNavigate} from "react-router-dom";
import WORDS from "../../../utils/words";
import useAuth from "../../../Hooks/useAuth";

const UserVideo = () => {

    const [counter, setCounter] = useState(false);
    const navigate = useNavigate();

    const {auth: {firstName , lastName}, setAuth} = useAuth();


    const mediaRecorderRef = useRef(null);

    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [voiceCaptchaParams, setVoiceCaptchaParams] = useState([]);

    useEffect(()=>{
        const shuffelWords = WORDS.sort(() => 0.5 - Math.random())
        setVoiceCaptchaParams(shuffelWords.slice(0,3));
    },[])




    const voiceCaptchaText = `${firstName} ${lastName}، جهت احراز هویت سجام`;

    const videoLengthSeconds = 15000;

    const webcamRef = useRef(null);

    const handleStartCaptureClick = useCallback(() => {
        console.log("start")

        setCounter(false)
        setCapturing(true);

        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: "video/webm"
        });
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        mediaRecorderRef.current.start();

        setTimeout(() => handleStopCaptureClick(), videoLengthSeconds);

    }, [webcamRef, setCapturing, mediaRecorderRef]);

    const handleDataAvailable = useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );


    useEffect(() => {
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                type: "video/webm"
            });
            const url = URL.createObjectURL(blob);
          //  setUrl(url)

            const videoData = {
                voiceCaptchaText,
                voiceCaptchaParams,
                url
            }

            navigate(RoutesName.UserVideoPreview , {state: {src: videoData}})

            //console.log("new url" , url)
        }

    }, [recordedChunks])

    const handleStopCaptureClick = useCallback(() => {
        mediaRecorderRef.current.stop();
        setCapturing(false);
/*
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                /!*type: "video/webm"*!/
            });
            const url = URL.createObjectURL(blob);

            console.log("url" , url)

            //navigate(RoutesName.UserVideoPreview , {state: {src: url}})
        }*/

    }, [mediaRecorderRef, webcamRef, setCapturing]);


    const handleDownload = useCallback(() => {
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                type: "video/webm"
            });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = "react-webcam-stream-capture.webm";
            a.click();
            window.URL.revokeObjectURL(url);
            setRecordedChunks([]);
        }
    }, [recordedChunks]);




    return (
        <Layout>
            <div className={`${classes.container} column jc-around ai-center rounded-8 height-100`}>

                <div className={`${classes.header} width-100 position-absolute column jc-start ai-center py-2 px-2 text-center`}>
                    { (capturing) ? <>
                        <span>{voiceCaptchaText}</span>
                        <span>{voiceCaptchaParams.map( name =>

                            <span className="mx-05" key={name}
                                   title={name}> {name} </span>

                        )}</span>
                    </> : <>
                    {!counter && <span>لطفا بعد از زدن دکمه شروع فیلم برداری متنی که نمایش داده می‌شود را با صدای رسا بخوانید:</span>}
                    </>}
                </div>
                <Webcam
                    //videoConstraints={videoConstraints}
                    mirrored={true}
                    audio={true}
                    muted={true}
                    // width="700px"
                    // height="10000px"

                    //width="100%"
                    height="100%"
                    ref={webcamRef}
                />
                {counter && <div className={`${classes.wrapper} width-100 flex jc-center ai-center position-absolute`}>
                    <Countdown
                        date={ Date.now() + 3000 }
                        renderer={props => <div className={`fs-30 font-weight-bold text-white`}>{props.seconds}</div>}
                        onComplete={() => handleStartCaptureClick()}
                    />
                </div>}

                {!counter && <div className={`${classes.frame} position-absolute width-100`} style={{backgroundImage: `url("${images.frame}")`}}/>}

                {(!capturing) ? !counter && <Button
                    buttonClass={`${classes.thisButton} cursor-pointer position-absolute`}
                    type="button"
                    buttonTitle="شروع"
                    //onClick={capture}

                    onClick={()=>setCounter(prevState => !prevState)}

                />:
                    <div className={`${classes.counting} position-absolute row jc-center ai-center`}>

                        <Countdown
                            date={ Date.now() + videoLengthSeconds }
                            renderer={props => <div className={`fs-20 font-weight-bold text-white`}>{props.seconds}</div>}
                            //onComplete={() => handleStartCaptureClick()}
                        />

                        <span className={`${classes.circle} mr-05`}/>
                    </div>

                }




            </div>
        </Layout>
    );
};

export default UserVideo;
