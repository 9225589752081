import React from 'react';
import classes from './HeaderBuilder.module.css'
import {images} from "../../assets/images/images";

const HeaderBuilder = ({step, title}) => {
    return (
        <div className={`${classes.container} width-100 row jc-between ai-center pb-2`}>

            <span>{step}</span>
            <span className={`font-weight-bold`}>{title}</span>

            <img src={images.shenasLogo} alt="shenasLogo" className={`${classes.logo}`}/>


        </div>
    );
};

export default HeaderBuilder;
