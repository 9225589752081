import React, {useEffect, useRef, useState} from 'react';
import Layout from "../../../components/Layout/Layout";
import classes from "../Liveness/Liveness.module.css";
import STEP from "../../../lang/steps";
import Webcam from "react-webcam";
import Button from "../../../components/Button/Button";
import MediaPipeNew from "./MediaPipe/MediaPipeNew";
import {images} from "../../../assets/images/images";
import {Navigate} from "react-router-dom";
import * as RoutesName from "../../../routes/routes";
import useAuth from "../../../Hooks/useAuth";
import {dataURItoBlob} from "../../../utils/generateMD5";


const items = ["TOP", "BOTTOM", "RIGHT", "LEFT"].sort(() => 0.5 - Math.random()).slice(0, 3);

const LivenessNew = () => {

    const timerInterval = 3;
    const webcamRef = useRef(null);
    const myTimer = useRef(null);

    const [step, setStep] = useState("BEFORE_START");
    const [sec, setSeconds] = useState(timerInterval)
    const [stepStatus, setStepStatus] = useState("");
    const [result, setResult] = useState([]);
    const [frame2Src, setFrame2Src] = useState(null);
    const {auth, setAuth} = useAuth();

    const [showTimer, setShowTimer] = useState(false);
    const [isLivenessStart, setIsLivenessStart] = useState(false);


    useEffect(() => {
        if (sec < 1) {
            setShowTimer(false)
            clearInterval(myTimer.current);
            setSeconds(timerInterval)
            if (step === "BEFORE_START") {
                setStep("SMILE")
            }
            if (step === "SMILE") {
                setStep(items[0])
            }
            if (step === items[0]) {
                setStep(items[1])
            }
            if (step === items[1]) {
                setStep(items[2])
            }
            if (step === items[2]) {
                setStep(items[3])
            }
        }
    }, [sec])
/*    useEffect(() => {
        if (result.length > 0) {
            setAuth({
                ...auth,
                successActionsList: result.success,
                failedActionsList: result.error
            })
            console.log({
                ...auth,
                successActionsList: result.success,
                failedActionsList: result.error
            })
        }
    }, [result])*/


    const capture = React.useCallback(
       () => {
           const frame2images = URL.createObjectURL(dataURItoBlob(webcamRef.current.getScreenshot()))

           setFrame2Src(frame2images)

          /*setAuth({
               ...auth,
               frame2: frame2images,
           });*/
       },
       [webcamRef]
   );


    const startTimer = () => {
        setSeconds(timerInterval)
        setShowTimer(true)
        setIsLivenessStart(true)
        myTimer.current = setInterval(() => {
            setSeconds(prev => {
                if (prev > 0) return prev - 1
            });
        }, 1000);
    };

    const start = () =>{
        capture()
        startTimer()
    }

    return (
        <Layout>
            <div className={`${classes.container}  column jc-around ai-center rounded-8 height-100`}>
                <div className={`${classes.header} width-100 position-absolute column jc-between ai-center py-4`}
                     style={backgroundBar(stepStatus)}>
                    {(isLivenessStart && !showTimer) &&
                        <span
                            className={`fs-03 width-90 text-center px-2 py-05 font-weight-bold rounded-8 ${classes.title}`}>
                        {STEP[step]}
                    </span>}
                    <img src={imgHandler(stepStatus)} className={`${imgClassHandler(stepStatus)} ${classes.icon}`}/>
                </div>
                {result.length === 0 ?
                    <>
                        <Webcam
                            mirrored={true}
                            audio={false}
                            screenshotFormat="image/jpeg"
                            height="100%"
                            ref={webcamRef}
                            videoConstraints={{
                                facingMode: "user",
                                frameRate: {ideal: 3, max: 3},
                            }}
                            style={{position: "absolute"}}
                        />
                        <MediaPipeNew
                            webcamRef={webcamRef}
                            items={items}
                            setStepStatus={setStepStatus}
                            step={step}
                            sec={sec}
                            frame2Src={frame2Src}
                            setResult={setResult}
                            startTimer={startTimer}
                        /></>
                    :
                    // <Navigate to={RoutesName.LivenessStatus} replace={true} state={result} />
                    // <Navigate to={RoutesName.LivenessStatus} replace={true} />
                    <Navigate to={RoutesName.LivenessStatus} replace={true} state={{res: result}}/>


                }


                {showTimer &&
                    <div className={`${classes.wrapper} width-100 flex jc-center ai-center position-absolute`}>
                        <span className={`fs-30 font-weight-bold text-white`}>{sec}</span>
                    </div>}
                <div className={`${classes.frame} position-absolute width-100`}
                     style={{backgroundImage: `url("${images.frame}")`}}/>

                {!isLivenessStart && <Button
                    buttonClass={`${classes.thisButton} cursor-pointer position-absolute`}
                    type="button"
                    buttonTitle="شروع"
                    onClick={start}
                />}

            </div>
        </Layout>
    );
};

export default LivenessNew;

const backgroundBar = (stepStatus) => {
    if (stepStatus === "done") {
        return {
            background: `linear-gradient(#ffffff00 0%, #ffffff00 30%,  #0da64596 100%)`,
        };
    }
    if (stepStatus === "failed") {
        return {
            background: `linear-gradient(#ffffff00 0%, #ffffff00 30%,  #ca201599 100%)`,
        };
    }
    return {
        background: `initial`,
    };
}
const imgHandler = (stepStatus) => {

    switch (stepStatus) {
        case "done":
            return images.check;
        case "failed":
            return images.close;
        default:
            return "";
    }
}

const imgClassHandler = (stepStatus) => {
    switch (stepStatus) {
        case "done":
            return classes.check;
        case "failed":
            return classes.close;
        default:
            return "";
    }
}