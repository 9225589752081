import {createContext, useState} from "react";
import {completeOtp} from "../api/auth";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({
        isLogin: false,
        nationalCode: null,
        sejamCode: null,
        firstName: null,
        lastName: null,
        gender: null,
        loginToken: null,
        completeOtp_accessToken: null,
        completeOtp_nextStepToken: null,
        completeOtp_kycFollowupCode: null,
        reqPayment_nextStepToken: null,
        reqPayment_paymentFollowupCode: null,
        submit_nextStepToken: null,
        query_nextStepToken: null,
        processStatus: null,
        queryResult: null,
        selfi: null,
        frame1: null,
        frame2: null,
        successActionsList: [],
        failedActionsList: [],
        video: null,
        signature: null,
        requestId: 1,
        processId: null,
    });
    // localStorage.setItem("auth", auth)

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;