import React, {useEffect, useRef} from 'react';
import {FaceMesh} from "@mediapipe/face_mesh";
import * as cam from "@mediapipe/camera_utils";
import useAuth from "../../../../Hooks/useAuth";
import {dataURItoBlob} from "../../../../utils/generateMD5";


const MediaPipeNew = (props) => {

    let camera;
    const {webcamRef, setStepStatus, step, sec, items, startTimer, setResult, frame2Src} = props;

    const secRef = useRef(null)
    const frame2SrcRef = useRef(null)
    const frame1SrcRef = useRef(null)
    const successRef = useRef([])
    const errorRef = useRef([])
    const stepRef = useRef("BEFORE_START")
    const {auth, setAuth} = useAuth();


    useEffect(() => {
        frame2SrcRef.current = frame2Src
    }, [frame2Src])

    useEffect(() => {
        const faceMesh = new FaceMesh({
            locateFile: (file) => {
                return `/tf-models/face_mesh/${file}`;
            }
        });

        faceMesh.setOptions({
            maxNumFaces: 1,
            refineLandmarks: true,
            minDetectionConfidence: 0.5,
            minTrackingConfidence: 0.5,
            selfieMode: true
        })

        if (typeof webcamRef.current !== "undefined" && webcamRef.current !== null) {
            camera = new cam.Camera(webcamRef.current.video, {
                onFrame: async () => {
                    await faceMesh.send(
                        {
                            image: webcamRef.current.video
                        })
                },
                width: 640,
                height: 480
            });
            camera.start()
        } else {
            camera.stop()
        }
        faceMesh.onResults(onResults);

        return () => camera.stop()
    }, [])


    useEffect(() => {

        if (sec < 1) {
            if (step === "BEFORE_START") {
                secRef.current = Date.now()
                stepRef.current = "SMILE"
            }
            if (step === "SMILE") {
                stepRef.current = items[0]
            }
            if (step === items[0]) {
                stepRef.current = items[1]
            }
            if (step === items[1]) {
                stepRef.current = items[2]
            }
        }
    }, [sec]);


    const nextStep = () => {

        if (successRef.current.length > 2) {
            camera.stop()
            setStepStatus("")
            setAuth({
                ...auth,
                successActionsList: successRef.current,
                failedActionsList: errorRef.current,
                frame2: frame2SrcRef.current,
                frame1: frame1SrcRef.current
            })
            return setResult({success: successRef.current, error: errorRef.current})

        }
        if (errorRef.current.length > 1) {
            camera.stop()
            setStepStatus("")
            setAuth({
                ...auth,
                successActionsList: successRef.current,
                failedActionsList: errorRef.current,
                frame2: frame2SrcRef.current,
                frame1: frame1SrcRef.current
            })
            return setResult({success: successRef.current, error: errorRef.current})
        }

        setTimeout(() => {
            setStepStatus("")
            secRef.current = Date.now() + 4500
            startTimer()
        }, 1500);

    }


    const captureMediaPipe = React.useCallback(
        () => {
            const frame1images = URL.createObjectURL(dataURItoBlob(webcamRef.current.getScreenshot()))
            frame1SrcRef.current = frame1images
        },
        [webcamRef]
    );


    const onResults = (results) => {

        //setDetectFace(results?.multiFaceLandmarks.length > 0)
        const face = results?.multiFaceLandmarks[0]
        if (!face) {
            return
        }

        const centerNose = face[94]
        const rightFace = face[123]
        const leftFace = face[93]
        const leftEye = face[243]
        const topNose = face[6]
        const leftTopFace = face[127]
        const rightDistance = Math.abs(rightFace.x - centerNose.x)
        const leftDistance = Math.abs(centerNose.x - leftFace.x)
        const topDistance = Math.abs(centerNose.y - leftEye.y)
        const bottomDistance = (topNose.y - leftTopFace.y)
        const startLip = face[78]
        const endLip = face[308]
        const lip = endLip.x - startLip.x

        if (stepRef.current === "SMILE") {
            if (Date.now() - secRef.current > 5000) {
                captureMediaPipe()
                setStepStatus("failed")
                errorRef.current.push("SMILE")
                stepRef.current = "FAILED_SMILE"
                nextStep()
            }
            if (lip > 0.088) {
                captureMediaPipe()
                setStepStatus("done")
                successRef.current.push("SMILE")
                stepRef.current = "FAILED_SMILE"
                nextStep()
            }
        }

        if (stepRef.current === "TOP") {

            if (Date.now() - secRef.current > 5000) {
                setStepStatus("failed")
                errorRef.current.push("TOP")
                stepRef.current = "FAILED_TOP"
                nextStep()
            }

            if (topDistance < 0.04) {
                setStepStatus("done")
                successRef.current.push("TOP")
                stepRef.current = "DONE_TOP"
                nextStep()
            }
        }
        if (stepRef.current === "BOTTOM") {

            if (Date.now() - secRef.current > 5000) {
                setStepStatus("failed")
                errorRef.current.push("BOTTOM")
                stepRef.current = "FAILED_BOTTOM"
                nextStep()
            }

            if (bottomDistance > 0.035) {
                setStepStatus("done")
                successRef.current.push("BOTTOM")
                stepRef.current = "DONE_BOTTOM"
                nextStep()
            }

        }

        if (stepRef.current === "RIGHT") {
            if (Date.now() - secRef.current > 5000) {
                setStepStatus("failed")
                errorRef.current.push("RIGHT")
                stepRef.current = "FAILED_RIGHT"
                nextStep()
            }

            if (leftDistance > 0.19) {
                setStepStatus("done")
                successRef.current.push("RIGHT")
                stepRef.current = "DONE_RIGHT"
                nextStep()
            }
        }

        if (stepRef.current === "LEFT") {
            if (Date.now() - secRef.current > 5000) {
                setStepStatus("failed")
                errorRef.current.push("LEFT")
                stepRef.current = "FAILED_LEFT"
                nextStep()
            }
            if (rightDistance < 0.05) {
                setStepStatus("done")
                successRef.current.push("LEFT")
                stepRef.current = "DONE_LEFT"
                nextStep()
            }
        }


    }


    return (
        <></>
    );
};

export default MediaPipeNew;
