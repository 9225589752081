import React, {useCallback, useRef} from 'react';
import classes from './UserSignature.module.css';
import {useNavigate} from "react-router-dom";
import * as RoutesName from "../../../routes/routes";
import Webcam from "react-webcam";
import Button from "../../../components/Button/Button";
import {images} from "../../../assets/images/images";
import Layout from "../../../components/Layout/Layout";

const UserSignature = () => {

    const navigate = useNavigate();

    /*const [src, setSrc] = useState('');*/


    const videoConstraints = {
        /*width: 300,
        height: 370,*/
        facingMode: "user"
    };

    const webcamRef = useRef(null);

    const capture = useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            // setSrc(imageSrc)

            navigate(RoutesName.UserSignaturePreview, {state: {src: imageSrc && imageSrc}})

        },
        [webcamRef]
    );

    return (
        <Layout>
            <div className={`${classes.container} column jc-around ai-center rounded-8 height-100`}>

                <div className={`${classes.header} width-100 position-absolute column jc-start ai-center py-3 px-2 text-center`}>
                    <span>لطفا توجه داشته باشید که فقط امضای شما در کادر تصویر قرار بگیرد.</span>
                </div>

                <Webcam
                    videoConstraints={videoConstraints}
                    //mirrored={true}
                    audio={false}
                    screenshotFormat="image/jpeg"
                    /* width={300}*/
                    height="100%"
                    ref={webcamRef}
                />

                <Button
                    buttonClass={`${classes.thisButton} cursor-pointer position-absolute`}
                    type="button"
                    buttonTitle="عکس بگیرید"
                    onClick={capture}
                />

                <div className={`${classes.frame} position-absolute width-100`}
                     style={{backgroundImage: `url("${images.SignatureFrame}")`}}/>


            </div>
        </Layout>
    );
};

export default UserSignature;
