import React from 'react';
import classes from './AnimationLayout.module.css'
import {Toaster} from "react-hot-toast";
import {Outlet, useLocation} from "react-router-dom";
import { motion } from "framer-motion";


const AnimationLayout = () => {

    const { pathname } = useLocation();
    const PageLayout = ({ children }) => children;

    const pageVariants = {
        initial: {
            opacity: 0
        },
        in: {
            opacity: 1
        },
        out: {
            opacity: 0
        }
    };

    const pageTransition = {
        type: "tween",
        ease: "linear",
        duration: 0.5
    };



    return (
        <div className={`${classes.container}`}>
            <PageLayout>
                <motion.div
                    key={pathname}
                    initial="initial"
                    animate="in"
                    variants={pageVariants}
                    transition={pageTransition}
                >
                    <Outlet />
                </motion.div>
            </PageLayout>
            <Toaster position="top-center" toastOptions={
                {
                    style: {
                        padding: "0.3vh 0.8vw 0.3vh 0",
                        color: "white",
                        lineHeight: "3vh",

                        borderRadius: "8px",
                        background: "#383a59",
                        maxWidth: "100%"
                    },
                    success: {
                        style: {
                            background: "#18a979",
                        },
                    },
                    error: {
                        style: {
                            background: "#d73e36",
                        },
                    },
                    custom: {
                        style: {
                            background: "#ff8124",
                        },
                    },
                }
            }/>
        </div>
    )
}

export default AnimationLayout