import React from 'react';
import classes from './UserSignatureHelp.module.css'
import {useNavigate} from "react-router-dom";
import * as RoutesName from "../../../routes/routes";
import HeaderBuilder from "../../../components/HeaderBuilder/HeaderBuilder";
import Button from "../../../components/Button/Button";
import Layout from "../../../components/Layout/Layout";

const UserSignatureHelp = () => {

    const navigate = useNavigate();

    const submit = async (e) => {
        e.preventDefault();

        navigate(RoutesName.UserSignature)

    }


    return (
        <Layout>
            <form onSubmit={(e) => submit(e)}
                  className={`${classes.container} column jc-between ai-center height-100 py-3 px-2`}>

                <HeaderBuilder step="گام 5" title="عکس برداری از امضا"/>

                <span>امضای ثبت شده توسط کارشناسان مورد برسی قرار می‌گیرد و به پرونده سجام شما الصاق و ذخیره خواهد شد. لذا لطفا:</span>

                <div className={`column jc-center ai-center width-85`}>
                    <span className={`list px-1 py-05 rounded-8 fs-0-8 width-100 text-center`}>تصویری با کیفیت مناسب ارائه دهید</span>
                    <span className={`list px-1 py-05 my-2 rounded-8 fs-0-8 width-100 text-center`}>ابتدا بر روی یک کاغذ سفید، امضای خود را درج کرده و سپس تصویر آن را ثبت کنید</span>
                    <span className={`list px-1 py-05 rounded-8 fs-0-8 width-100 text-center`}>توجه داشته باشیید که صرفا امضا در کادر تصویر قرار بگیرد</span>
                </div>

                <span>بعد از انجام فرایند و پیش از ارسال تصویر به رایانه مرکزی، در صورت عدم رضایت از کیفیت، عکس برداری را تکرار کنید. </span>


                <Button
                    buttonClass={`${classes.thisButton} cursor-pointer`}
                    type="submit"
                    buttonTitle="شروع"
                />
            </form>
        </Layout>
    );
};

export default UserSignatureHelp;
