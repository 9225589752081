import SparkMD5 from 'spark-md5';

export const generateMD5 = file => {
    return new Promise((resolve, reject) => {
        let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice,
            chunkSize = 2097152,                             // Read in chunks of 2MB
            chunks = Math.ceil(file.size / chunkSize),
            currentChunk = 0,
            spark = new SparkMD5.ArrayBuffer(),
            fileReader = new FileReader();

        fileReader.onload = function (e) {
            spark.append(e.target.result);                   // Append array buffer
            currentChunk++;
            if (currentChunk < chunks) {
                loadNext();
            } else {
                resolve( spark.end()) // Compute hash
            }
        };
        fileReader.onerror = reject;

        function loadNext() {
            let start = currentChunk * chunkSize,
                end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize;

            fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
        }
        loadNext();
    })
}


function readFileAsync(file) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsArrayBuffer(file);
    })
}


export function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    try {
        return new Blob([ab], {type: mimeString});
    } catch (e) {
        const BlobBuilder = window.WebKitBlobBuilder || window.MozBlobBuilder;
        const bb = new BlobBuilder();
        bb.append(ab);
        return bb.getBlob(mimeString);
    }
}



export async function createFile(fileData, md5) {
    const md5ArrayBuffer = new Uint8Array(md5.match(/[\da-f]{2}/gi).map(function (h) {
        return parseInt(h, 16)
    }))
    //const fileArrayBuffer = await readFileAsync(fileData)
    const a = await new Blob([md5ArrayBuffer, await fileData.arrayBuffer()], {type: "image/jpeg"});
    return a
}

