import React from 'react';
import classes from './UserImageHelp.module.css'
import Button from "../../../components/Button/Button";
import Layout from "../../../components/Layout/Layout";
import * as RoutesName from "../../../routes/routes";
import {useNavigate} from "react-router-dom";
import HeaderBuilder from "../../../components/HeaderBuilder/HeaderBuilder";

const UserImageHelp = () => {

    const navigate = useNavigate();

    const submit = async (e) => {
        e.preventDefault();
        navigate(RoutesName.UserImage)
    }

    return (
        <Layout>
            <form onSubmit={(e) => submit(e)}
                  className={`${classes.container} column jc-between ai-center height-100 py-3 px-2`}>

                <HeaderBuilder step="گام ۱" title="عکس برداری از چهره"/>

                <span>تصویر ثبت شده علاوه بر پردازش رایانه‌‌ای، توسط کارشناسان مورد برسی قرار می‌گیرد و به پرونده سجام شما الصاق و ذخیره خواهد شد. لذا لطفا: </span>

                <div className={`column jc-center ai-center width-85`}>
                    <span className={`list px-1 py-05 rounded-8 fs-0-8 width-100 text-center`}>تمام چهره با رعایت پوشش مناسب به وضوح مشخص باشد</span>
                    <span className={`list px-1 py-05 my-2 rounded-8 fs-0-8 width-100 text-center`}>نور و پس زمینه عکس مناسب و تک رنگ باشد</span>
                    <span className={`list px-1 py-05 rounded-8 fs-0-8 width-100 text-center`}>زاویه عکس از روبرو باشد</span>
                </div>

                <span>بعد از انجام فرایند و پیش از ارسال تصویر به رایانه مرکزی، در صورت عدم رضایت از کیفیت، عکس برداری را تکرار کنید. </span>


                <Button
                    buttonClass={`${classes.thisButton} cursor-pointer`}
                    type="submit"
                    buttonTitle="شروع"
                />
            </form>
        </Layout>
    );
};

export default UserImageHelp;
