export const validateEmail = (email) => {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(email);
}

export const dateValidator = str => {
    return /^[1-4]\d{3}\/((0[1-6]\/((3[0-1])|([1-2][0-9])|(0[1-9])))|((1[0-2]|(0[7-9]))\/(30|31|([1-2][0-9])|(0[1-9]))))$/.test(str);
}

export const timeValidator = str => {
    return /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/.test(str);
}

export const isValidNationalCode = input => {
    if (!/^\d{10}$/.test(input)) return false;
    const check = +input[9];
    const sum = input.split('').slice(0, 9).reduce((acc, x, i) => acc + +x * (10 - i), 0) % 11;
    return sum < 2 ? check === sum : check + sum === 11;
}
export const ROLES = {admin: "admin", merchant: "merchant_basic"}

export const getBase64 = (value) => {
    if(!(value instanceof Blob)) return value
    return new Promise(resolve => {
        let reader = new FileReader();
        reader.readAsDataURL(value);
        reader.onload = () => {
            resolve(reader.result);
        };
    });
}



export const createBlob2 = (res) => {
    const blob = new Blob([res.data], {type: res.headers['content-type']});
    return URL.createObjectURL(blob);
}

export const createBlob = (res) => {
    const blob =  new Blob([res]);
   return  URL.createObjectURL(blob)
}
