import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Main from "./main/Main";
import {QueryClient, QueryClientProvider,} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'

import "./assets/fontIcon/css/agah.css";
import {AuthProvider} from "./context/AuthProvider";


const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <AuthProvider>
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false}/>
                {/*<BrowserRouter basename={window.env.REACT_APP_DIR}>*/}
                <BrowserRouter>
                    <Routes>
                        <Route path="/*" element={<Main/>}/>
                    </Routes>
                </BrowserRouter>
            </QueryClientProvider>
        </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
