import React, {useEffect, useRef, useState} from 'react';
import classes from './Agreement.module.css'
import HeaderBuilder from "../../../components/HeaderBuilder/HeaderBuilder";
import Button from "../../../components/Button/Button";
import Layout from "../../../components/Layout/Layout";
import {useNavigate} from "react-router-dom";
import * as RoutesName from "../../../routes/routes";
import ScrollBar from "../../../components/ScrollBar";
import useAuth from "../../../Hooks/useAuth";
import {completeOtp, query, requestPayment, sign, uploadVideo} from "../../../api/auth";
import {createFile, dataURItoBlob, generateMD5} from "../../../utils/generateMD5";
import toast from "react-hot-toast";
import Loading from "../../../components/Loading/Loading";
import axios from "axios";
import useInterval from "../../../Hooks/useInterval";

const Agreement = () => {


    const navigate = useNavigate();
    const {auth, setAuth} = useAuth();


    const [agree, setAgree] = useState(false)

    const [upload, setUpload] = useState(true)

    const[loading, setLoading]= useState(false)

    const nextStepTokenRef = useRef(null)




    let nextStepToken = null;



    const uploadFileFunc = async (newAuth)=> {



        try {
            const signSrcBlob = await axios({
                method: "get",
                url: auth.signature,
                responseType: "blob",
            })
            const signSrcMD5 = await generateMD5(signSrcBlob.data)
            const signFile = await createFile(signSrcBlob.data, signSrcMD5)

            const videoSrcBlob = await axios({
                method: "get",
                url: auth.video.url,
                responseType: "blob",
            })
            const videoFile = videoSrcBlob.data

            const videoText = auth.video.voiceCaptchaText + " " + auth.video.voiceCaptchaParams

            const uploadVideoFunc = await uploadVideo(videoText, signFile, videoFile, auth.processId, auth.requestId, auth.completeOtp_accessToken, auth.query_nextStepToken)

            nextStepTokenRef.current = uploadVideoFunc.data.nextStepToken


            setUpload(false)





        } catch (uploadVideoFunc) {
            if (uploadVideoFunc.response.status !== 200) {
                toast.error(uploadVideoFunc.response.data.message ? uploadVideoFunc.response.data.message : "خطا در سرور!")
                //return false;
            }
        }

    }


    const signFunction = async () => {


        const signData = {
            confirm: true,
            requestId: auth.requestId,

        }
        try {
            const signFunc = await sign(auth.completeOtp_accessToken, auth.processId, nextStepTokenRef.current ,signData)
            navigate(RoutesName.Result)

        } catch (signFunc) {
        }
    }


    useEffect(() => {

        uploadFileFunc()

    }, []);









    const agreeFunc = () => {

        if (agree && !upload) {

            signFunction()

          /*  setLoading(true)
            if (upload) {
                navigate(RoutesName.Result)
            }
            console.log("in")*/
        }

    }


    return (
        <Layout>
            <div className={`${classes.container} column jc-between ai-center height-100 py-3 px-2`}>
                <HeaderBuilder step="گام 8" title="تائید فرم توافق‌نامه"/>
                <ScrollBar>
                    <div className={`py-2`}>
                        <span>اینجانب {auth.firstName} {auth.lastName} به شماره ملی {auth.nationalCode} بدین وسیله ضمن تصدیق صحت کلیه مندرحات و موارد اعلامی فوق و مطابقت کامل با واقعیت و اسناد هویتی مربوطه موارد زیر را اعلام، اقرار، تائید و تعهد می‌نمایم:</span>

                        <div className={`column jc-center ai-center width-85 m-auto`}>
                            <span className={`list px-1 py-1 rounded-8 fs-0-9 width-100 my-1`} style={{textAlign: 'justify'}}>
                                1)	آگاهی دارم سامانه سجام به منظور ارائه خدمات زیرساختی یکپارچه و فراگیر و نیز ایجاد درگاه واحد خدمات‌دهی به تمامی فعالان بازار سرمایه و بویژه سرمایه‌گذاران حقیقی و حقوقی راه‌اندازی شده است و با ثبت نام در این سامانه، بصورت غیرقابل برگشت اجازه می‌دهم اطلاعات ثبت شده در این سامانه، در راستای شناسایی اینجانب و ارائه خدمات، توسط سازمان و اشخاص تحت نظارت سازمان مورد استفاده قرار گیرد.
                            </span>
                            <span className={`list px-1 py-1 rounded-8 fs-0-9 width-100 my-1`} style={{textAlign: 'justify'}}>
                                2)	تایید می‌نمایم اطلاعات یاد شده دقیقا همان اطلاعاتی است که اینجانب در جریان ثبت‌نام در سامانه جامع اطلاعات مشتریان (سجام) وارد کرده‌ام.
                            </span>
                            <span className={`list px-1 py-1 rounded-8 fs-0-9 width-100 my-1`} style={{textAlign: 'justify'}}>
                                3)	تایید می‌نمایم کلیه اطلاعات، اسناد و مدارک ارائه شده توسط اینجانب صحیح، اصیل، دقیق و منطبق با واقع بوده و از مسئولیت‌ها و عواقب قانونی هرگونه عدم رعایت موارد فوق آگاهی کامل دارم.
                            </span>
                            <span className={`list px-1 py-1 rounded-8 fs-0-9 width-100 my-1`} style={{textAlign: 'justify'}}>
                                4)	تایید می‌نمایم اطلاعات ارتباطی اعلامی شامل نشانی و کدپستی محل اقامت، شماره تلفن ثابت و همراه و پست الکترونیکی اعلامی به شرح فوق متعلق  و یا در اختیار اینجانب بوده و ارسال هرگونه ابلاغیه، اخطار، پیام، مراسلات و مکاتبه با نشانی‌های یاد شده به طرق مذکور به منزله ابلاغ به اینجانب تلقی شده و مسئولیت سوءاستفاده از اطلاعات ارسالی به شیوه¬های بیان شده، صرفاً بر عهده اینجانب می‌باشد.
                            </span>
                            <span className={`list px-1 py-1 rounded-8 fs-0-9 width-100 my-1`} style={{textAlign: 'justify'}}>
                                4-1) تعهد می¬نمایم تغییرات کدپستی و اطلاعات مربوط به نشانی خود را به مراجع ذی¬ربط اطلاع دهم. آخرین نشانی اینجانب، نشانی ارائه شده از سوی مراجع مربوطه است حتی اگر اینجانب نشانی خود را راساً اصلاح نکرده باشم.
                            </span>
                            <span className={`list px-1 py-1 rounded-8 fs-0-9 width-100 my-1`} style={{textAlign: 'justify'}}>
                                4-2) تعهد می¬نمایم هرگونه تغییر در اطلاعات ارائه شده را در اولین فرصت در سجام بروزرسانی ¬نمایم.
                            </span>
                            <span className={`list px-1 py-1 rounded-8 fs-0-9 width-100 my-1`} style={{textAlign: 'justify'}}>
                                5)	آگاهی دارم شناسایی اینجانب توسط اشخاص تحت نظارت سازمان منحصرا از طریق شناسه‌ای صورت خواهد گرفت که به شماره تلفن همراه اعلامی اینجانب، مندرج در این فرم، ارسال خواهد شد (رمز OTP). با توجه به اینکه تایید می‌نمایم شماره تلفن همراه اعلامی در سامانه سجام و ثبت شده در این فرم متعلق و یا در اختیار اینجانب است، بنابراین هرگونه استفاده از شناسه مزبور منتسب به اینجانب بوده و مسئولیت دریافت و انجام هرگونه خدمات مالی از جمله سفارش خرید یا فروش یا معامله که از طریق شناسه ارسالی به شماره تلفن همراه اینجانب صورت می‌پذیرد را بر عهده می‌گیرم.
                            </span>
                            <span className={`list px-1 py-1 rounded-8 fs-0-9 width-100 my-1`} style={{textAlign: 'justify'}}>
                                6)	آگاهی دارم و تائید می‌نمایم با ارائه رمز OTP به هریک از اشخاص تحت نظارت در بازار سرمایه که از آنها سابقاً خدمات دریافت کرده‌ام یا می‌نمایم، ازجمله کارگزار منتخب خود، استفاده از اطلاعات مربوط به اینجانب در سجام، اصلاحات و بروزرسانی‌‌های بعدی آن را در چارچوب قوانین و مقررات به شخص تحت نظارت مزبور و نیز هر شخص یا نهادی که شخص تحت نظارت از آن خدمات می‌گیرد بصورت دائم و غیرقابل برگشت اجازه داده‌ام.
                            </span>
                            <span className={`list px-1 py-1 rounded-8 fs-0-9 width-100 my-1`} style={{textAlign: 'justify'}}>
                                7)	تعهد می‌نمایم، در صورت تغییر در هریک از اطلاعات اعلامی، مراتب را به محض وقوع به نحو مقتضی در سامانه سجام ثبت و اصلاح نمایم، بدیهی است مسئولیت عدم اعلام به موقع صرفاً بر عهده اینجانب می‌باشد.
                            </span>
                            <span className={`list px-1 py-1 rounded-8 fs-0-9 width-100 my-1`} style={{textAlign: 'justify'}}>
                                8)	آگاهی دارم و تائید می‌نمایم درصورتی‌که عضو هیات مدیره، هیات عامل/مدیرعامل، هیات تصفیه، هیات امنا، حسابرس، بازرس و یا هر رکن مشابه در هر شخص حقوقی باشم، اطلاعات ارائه شده توسط اینجانب به عنوان شخص حقیقی در سامانه سجام، بخشی از اطلاعات آن شخص حقوقی نیز محسوب شده و قابل استفاده و ارائه در کنار اطلاعات آن شخص حقوقی ‌باشد.
                            </span>
                            <span className={`list px-1 py-1 rounded-8 fs-0-9 width-100 my-1`} style={{textAlign: 'justify'}}>
                                9)	تعهد می‌نمایم ضمن رعایت دقیق قوانین و مقررات مبارزه با پولشویی، اطلاعات مورد درخواست سازمان، در موضوع مبارزه با پولشویی را به نحوی که سازمان تعیین می کند جهت انجام وظایف محول شده تامین نمایم.
                            </span>
                            <span className={`list px-1 py-1 rounded-8 fs-0-9 width-100 my-1`} style={{textAlign: 'justify'}}>
                                10)	تعهد می¬نمایم اجازه استفاده اشخاص دیگر را از خدمات پایه دریافتی (کدمعاملاتی) و همچنین رمز OTP ندهم و در صورت وقوع این امر، موضوع را بلافاصله به شخص تحت نظارت مربوطه اطلاع دهم.
                            </span>
                            <span className={`list px-1 py-1 rounded-8 fs-0-9 width-100 my-1`} style={{textAlign: 'justify'}}>
                                11)	اعلام و تائید می‌نمایم نمونه امضای مندرج در این برگه متعلق به اینجانب است و هر زمان بابت دریافت هریک از خدمات در بازار سرمایه نیاز به ارائه امضاء باشد، امضای درج شده در این سند، ملاک تطبیق امضاء جهت ارائه خدمات می‌باشد.
                            </span>
                            <span className={`list px-1 py-1 rounded-8 fs-0-9 width-100 my-1`} style={{textAlign: 'justify'}}>
                                12)	اینجانب با آگاهی از قوانین و مقررات و رویه¬های فعالیت در بازار سرمایه، از خدمات سامانه سجام استفاده کرده و مسئولیت قانونی ارائه اطلاعات خلاف واقع، نادرست و گمراه¬کننده را بر عهده گرفته و حق هرگونه ادعا، اعتراض و شکایت در این خصوص را از خود سلب و ساقط نمودم. همچنین شرکت سپرده¬گذاری مرکزی اوراق بهادار و تسویه وجوه حق دارد نسبت به قطع ارائه خدمات در صورت احراز ارائه اطلاعات خلاف واقع، نادرست و گمراه¬کننده به هر طریق و در هر زمان مبادرت نماید.
                            </span>
                            <span className={`list px-1 py-1 rounded-8 fs-0-9 width-100 my-1`} style={{textAlign: 'justify'}}>
                                13)	کلیه نهادهای مالی درصورت نیاز می‌توانند تصویر مصدق فرم‌های شناسایی اینجانب را از شرکت سپرده‌گذاری مرکزی اوراق بهادار و تسویه وجوه اخذ نمایند.
                            </span>
                            <span className={`list px-1 py-1 rounded-8 fs-0-9 width-100 my-1`} style={{textAlign: 'justify'}}>
                                14)	تعهد می‌نمایم که کلیه اقدامات اینجانب در چارچوب قوانین و مقررات حاکم بر بازار سرمایه باشد، و از هرگونه مشارکت و فعالیت (به ویژه در فضای مجازی) در زمینه‌هایی که مطابق قوانین بازار اوراق بهادار نیازمند اخذ مجوز از سازمان می‌باشد، بدون اخذ مجوزهای لازم خودداری نمایم. همچنین از عضویت و فعالیت در شبکه‌های اجتماعی، سایت‌ها و کانال‌هایی که برخلاف مقررات اقدام به ارائه تحلیل در خصوص اوراق بهادار می‌نمایند و تحت عناوینی همچون سیگنال فروشی اقدام به اغوای قیمتی می‌نمایند، خودداری و پرهیز نمایم. بدیهی است در غیر این صورت سازمان، بورس‌ها و فرابورس ایران می‌توانند کد معاملاتی اینجانب را مسدود نموده و علاوه بر آن برخوردهای قانونی را از طریق مراجع ذی‌صلاح نسبت به اینجانب پیگیری نمایند. همچنین تعهد می‌نمایم کلیه خسارات و زیان‌های ناشی از اینگونه اقدامات که متوجه اینجانب باشد جبران نموده و حق هرگونه ایراد، اعتراض و پیگیری را نسبت اقدامات سازمان، بورس‌ها و فرابورس ایران از خود سلب و ساقط می‌نمایم.
                            </span>
                            <span className={`list px-1 py-1 rounded-8 fs-0-9 width-100 my-1`} style={{textAlign: 'justify'}}>
                                15)	اینجانب آگاهی کامل دارم که بازار سرمایه، همراه با سود و زیان بوده و سرمایه‌گذاری همواره با مخاطرات و ریسک است و سرمایه‌گذاری در این بازار مبتنی بر بررسی‌ها و تشخیص سرمایه‌گذار بوده و سود و سرمایه از سوی هیچ شخص حقیقی و یا حقوقی مورد تضمین نیست. لذا با علم به این مخاطرات مبادرت به دریافت کد معاملاتی و سرمایه‌گذاری در بازار سرمایه می‌نمایم.
                            </span>

                        </div>

                    </div>

                    <div className={`mb-2 row jc-center ai-center`}>
                        <input type="checkbox" className="direction-ltr ml-025" data-name="verify"
                            //value={step}
                               checked={agree}
                               onChange={() => setAgree(prevState => !prevState)}
                        />
                        <span className={`mr-025`}>موارد ذکر شده در فرم بالا مورد تایید است.</span>
                    </div>

                </ScrollBar>




                    <Button
                        buttonClass={`${classes.thisButton} ${classes.next} cursor-pointer mt-3`}
                        type="button"
                        buttonTitle="تائید"
                        disabled={!agree}
                        onClick={() => agreeFunc()}
                    />



            </div>

            {loading && <Loading/>}
        </Layout>
    );
};

export default Agreement;
