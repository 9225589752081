import React from 'react';
import classes from './LivenessHelp.module.css'
import HeaderBuilder from "../../../components/HeaderBuilder/HeaderBuilder";
import Button from "../../../components/Button/Button";
import {useNavigate} from "react-router-dom";
import * as RoutesName from "../../../routes/routes";
import Layout from "../../../components/Layout/Layout";
import useAuth from "../../../Hooks/useAuth";

const LivenessHelp = () => {

    const navigate = useNavigate();





    const submit = async (e) => {
        e.preventDefault();

         navigate(RoutesName.Liveness)
        //navigate(RoutesName.UserVideo)

    }

    return (
        <Layout>

            <form onSubmit={(e) => submit(e)}
                  className={`${classes.container} column jc-between ai-center height-100 py-3 px-2`}>

                <HeaderBuilder step="گام 3" title="فیلم‌برداری از چهره"/>

                <span className={`fs-0-9`}>فیلم ثبت شده علاوه بر پردازش رایانه‌‌ای، توسط کارشناسان مورد برسی قرار می‌گیرد و به پرونده شجام شما الصاق و ذخیره خواهد شد. لذا لطفا: </span>
                <div className={`column jc-center ai-center width-85`}>
                    <span className={`list px-1 py-05 rounded-8 fs-0-8 width-100 text-center`}>تمام چهره با رعایت پوشش مناسب به وضوح مشخص باشد</span>
                    <span className={`list px-1 py-05 my-1 rounded-8 fs-0-8 width-100 text-center`}>نور و پس زمینه عکس مناسب و تک رنگ باشد</span>
                    <span
                        className={`list px-1 py-05 rounded-8 fs-0-8 width-100 text-center`}>زاویه عکس از روبرو باشد</span>
                </div>

                <span className={`fs-0-9`}>برای اطمینان از حضور شخص شما در برابر دوربین، ازشما خواسته می‌شود لبخند بزنید، سر خود را به آرامی به اطراف بگردانید و جمله نمایش داده شده را با صدای رسا بگویید. لطفا طبق پیام اعلامی از طرف نرم افزار، حرکت های درخواستی را انجام دهید.</span>
                <span className={`fs-0-9`}>بعد از انجام فرایند و پیش از ارسال تصویر به رایانه مرکزی، در صورت عدم رضایت از کیفیت، عکس برداری را تکرار کنید. </span>


                <Button
                    buttonClass={`${classes.thisButton} cursor-pointer`}
                    type="submit"
                    buttonTitle="ثبت"
                />
            </form>
        </Layout>

    );
};

export default LivenessHelp;
