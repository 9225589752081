import React from "react";
import {images} from "../../assets/images/images";
import Layout from "../Layout/Layout";
import classes from './Loading.module.css'

const Loading = ({isFullPage, type}) => {
    return (
        <div className={`flex jc-center ai-center height-100 ${classes.container}`}>
            <img src={images.crloading} alt="" className={`${classes.image}`}/>
        </div>

    );
};

export default Loading;