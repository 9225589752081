import React from "react";
import {images} from "../../assets/images/images";
import Layout from "../Layout/Layout";
import classes from './Info.module.css'
import Icon from "../Icon/Icon";
import Button from "../Button/Button";

const Info = ({back}) => {
    return (
        <div className={`column jc-between ai-center height-100 py-4 rounded-8 ${classes.container}`}>

            {/*<Icon
                iconName="icon-cancel flex fs-07"
                iconClass={`${classes.thisIcon} hover-text cursor-pointer`}
                onClick={()=>back()}
            />*/}
            <span className={`font-weight-bold fs-02`}>راهنما</span>



            <div className={`column jc-center ai-center width-100`}>
                <span className={` mb-1`}>سریال کارت ملی هوشمند</span>
                <img src={images.id_card_serial} alt="" className={`${classes.image} rounded-8 mt-1`}/>
            </div>
            <div className={`column jc-center ai-center width-100 mb-2`}>
                <span className={`mb-1`}>کدرهگیری رسید ثبت‌نام کارت ملی هوشمند</span>
                <img src={images.id_card_receipt} alt="" className={`${classes.image} rounded-8 mt-1`}/>
            </div>

            <Button
                buttonClass={`${classes.thisButton} cursor-pointer`}
                type="submit"
                buttonTitle="متوجه شدم"
                onClick={()=>back()}
            />


        </div>

    );
};

export default Info;