import React from 'react';
import {useNavigate} from "react-router-dom";
import * as RoutesName from "../../../routes/routes";
import Button from "../../Button/Button";

import classes from "./Missing.module.css"

const Missing = () => {

    const navigate = useNavigate();

    return (
        <div className={`height-100 column jc-center ai-center`} style={{height: '100vh'}}>
            {/*<img className={`mb-2`} src={images.pageNotFound} alt="404" style={{width: "25vw"}}/>*/}
            <span className={`my-2 font-weight-bold fs-50 text-red`}>404</span>
            <span className={`my-2 font-weight-bold fs-02 text-red`}>صفحه مورد نظر پیدا نشد...!</span>
            <Button
                buttonClass={`${classes.thisButton} cursor-pointer mt-5`}
                type="submit"
                buttonTitle="صفحه اصلی"
                onClick={() => navigate(RoutesName.Login)}
            />
        </div>
    );
};

export default Missing;
