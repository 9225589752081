const WORDS = [
    'آب',
    'زمین',
    'درخت',
    'دریا',
    'کوه',
    'آسمان',
    'قرمز',
    'سبز',
    'زرد',
    'سبز',
    'سفید',
    'سیب',
    'دیوار',
    'خانه',
    'میز',
    'خوب',
    'زیبا',
    'آرام',
    'میوه',
    'نان',
    'گل',
]
export default WORDS;