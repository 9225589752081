import React from 'react';
import classes from './UserImagePreview.module.css'
import Button from "../../../components/Button/Button";
import Layout from "../../../components/Layout/Layout";
import * as RoutesName from "../../../routes/routes";
import {useLocation, useNavigate} from "react-router-dom";
import {images} from "../../../assets/images/images";
import HeaderBuilder from "../../../components/HeaderBuilder/HeaderBuilder";
import useAuth from "../../../Hooks/useAuth";
import {dataURItoBlob} from "../../../utils/generateMD5";

const UserImagePreview = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const {auth, setAuth} = useAuth();



    const imageSrc = location.state?.src || "";


  /*  setAuth({UserImageSrc: imageSrc});*/



    const prev = () => {
      navigate(RoutesName.UserImage)
    }
    const next = () => {
        setAuth({
            ...auth,
             selfi: URL.createObjectURL(dataURItoBlob(imageSrc)),
            // selfi: imageSrc,
        });
      navigate(RoutesName.LivenessHelp)
    }

    return (
        <Layout>
            <div className={`${classes.container} column jc-between ai-center height-100 py-3 px-2`}>
                <HeaderBuilder step="گام 2" title="تائید عکس چهره"/>
                <span>عکس زیر از چهره شما ثبت و بعد تائید به رایانه مرکزی ارسال می‌شود.</span>





                <img src={imageSrc ? imageSrc : images.shenasLogo} className={`${classes.image} rounded-8`}
                     alt=""/>
              <div className={`row width-100 jc-center ai-center`}>
                  <Button
                      buttonClass={`${classes.thisButton} ${classes.prev} cursor-pointer ml-05`}
                      type="button"
                      buttonTitle="تکرار عکس"
                      onClick={() => prev()}
                  />
                  <Button
                      buttonClass={`${classes.thisButton} ${classes.next} cursor-pointer mr-05`}
                      type="button"
                      buttonTitle="تائید"
                      onClick={() => next()}
                  />
              </div>




            </div>
        </Layout>
    );
};

export default UserImagePreview;
