import React, {useEffect, useState} from 'react';
import classes from './Result.module.css'
import {images} from "../../../assets/images/images";
import Layout from "../../../components/Layout/Layout";
import useAuth from "../../../Hooks/useAuth";
import Button from "../../../components/Button/Button";
import * as RoutesName from "../../../routes/routes";
import {useNavigate} from "react-router-dom";
import {localStatus} from "../../../api/auth";
import RESULT from "../../../lang/result";
import Loading from "../../../components/Loading/Loading";

const Result = () => {

    const navigate = useNavigate();
    const {auth, setAuth} = useAuth();

    console.log("auth" , auth)

    const[loading, setLoading]= useState(true)
    const[error, setError] = useState(false)
    const[status, setStatus]= useState(null)


    const checkLocalStatus = async () => {

        const localStatusData = {
            nationalCode: auth.nationalCode,
            sejamCode: auth.sejamCode,
        }

        try {

            const localStatusFunc = await localStatus(auth.loginToken, localStatusData)

            setStatus(localStatusFunc.data.processStatus)
            setLoading(false)


        } catch (localStatusFunc) {

        }

    }


    useEffect(()=>{
        checkLocalStatus().then(()=>{

        })
    }, [])

    const startAgain = () => {
        setAuth({
            nationalCode: null,
            firstName: null,
            lastName: null,
            gender: null,
            completeOtp_accessToken: null,
            completeOtp_nextStepToken: null,
            completeOtp_kycFollowupCode: null,
            reqPayment_nextStepToken: null,
            reqPayment_paymentFollowupCode: null,
            submit_nextStepToken: null,
            query_nextStepToken: null,
            processStatus: null,
            queryResult: null,
            selfi: null,
            frame1: null,
            frame2: null,
            successActionsList: [],
            failedActionsList: [],
            video: null,
            signature: null,
            requestId: 1,
            processId: null,
        });
        navigate(RoutesName.Login)
    }

    const content = () => {

        if (status === null) {
            return <>
                <img src={images.crossAnimation} className={`${classes.image} floating rounded-8`} alt=""/>
                <span className={` text-center my-7`}>هویت شما توسط سامانه هوشمند احراز نشد.</span>
                <Button
                    buttonClass={`${classes.thisButton} cursor-pointer`}
                    type="submit"

                    onClick={() => startAgain()}
                    buttonTitle="شروع دوباره فرایند"
                />
            </>
        }
        if (status === "REJECT") {
            return <>
                <img src={images.crossAnimation} className={`${classes.image} floating rounded-8`} alt=""/>
                <span className={`text-center my-7`}>{RESULT[status]}</span>
                <Button
                    buttonClass={`${classes.thisButton} cursor-pointer`}
                    type="submit"

                    onClick={() => startAgain()}
                    buttonTitle="شروع دوباره فرایند"
                />
            </>
        }
        if (status !== "REJECT" && status !== null) {
            return <>
                <img src={images.checkAnimation} className={`${classes.image} floating rounded-8 mb-3`} alt=""/>
                <span className={`text-center mt-3`}>{RESULT[status]}</span>
            </>
        }
    }


    return (
        <Layout>
            <div className={`${classes.container} column jc-center ai-center height-100 py-3 px-2 ${loading && 'blur-2'}`}>
                {content()}
            </div>
            {loading && <Loading/>}
        </Layout>
    );
};

export default Result;
