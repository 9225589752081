import React from 'react';
import classes from "../../main/pages/Login/Login.module.css";

const Layout = ({children}) => {
    return (
        <div className={`${classes.container} height-100 width-100 position-absolute rounded-8 container-bg`}>
            {children}
        </div>
    );
};

export default Layout;
