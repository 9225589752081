import React, {useEffect, useRef, useState} from 'react';
import classes from './Liveness.module.css'
import Button from "../../../components/Button/Button";
import Layout from "../../../components/Layout/Layout";
import {images} from "../../../assets/images/images";
import {useNavigate} from "react-router-dom";

import Webcam from "react-webcam";
import STEP from "../../../lang/steps";
import MediaPipe from "./MediaPipe/MediaPipe";
import useAuth from "../../../Hooks/useAuth";
import {dataURItoBlob} from "../../../utils/generateMD5";

const items = ["TOP", "BOTTOM", "RIGHT", "LEFT"].sort(() => 0.5 - Math.random()).slice(0, 3);

const Liveness = () => {

    const timerInterval = 3;
    const {auth, setAuth} = useAuth();

    const [white, setWhite] = useState(false);
    const [count, setCount] = useState(false);
    const [actionStatus, setActionStatus] = useState("");
    const [actionStep, setActionStep] = useState("BEFORE_START");


    const [time, setTime] = useState(timerInterval)

    // const [detecting, setDetecting] = useState(true);
    const [detectFace, setDetectFace] = useState(false);
    const detectFaceRef = useRef(null)


    const fram2Ref = useRef(null)



    /* let selectAction = useEffect({

     },[])*/


    const myTimer = useRef(null);


    useEffect(() => {


        if (time < 1) {
            clearInterval(myTimer.current);
            setCount(false)
            setTime(timerInterval)
            if (actionStep === "BEFORE_START") {
                setActionStep("SMILE")
            }
            if (actionStep === "SMILE") {
                setActionStep(items[0])

            }
            if (actionStep === items[0]) {
                setActionStep(items[1])
            }
            if (actionStep === items[1]) {
                setActionStep(items[2])
            }
            if (actionStep === items[2]) {
                setActionStep(items[3])
            }
        }
    }, [time])

    const reloadTimer = () => {
        setTime(timerInterval)
    }

    const startTimer = () => {
        setCount(true)

        /*        if (actionStepRef.current === "DONE_" + items[2]) {
                    return submit()
                }*/


        if (!count) {
            myTimer.current = setInterval(() => {
                setTime(prev => {
                    if (prev > 0) return prev - 1
                });
            }, 1000);
        }
    };


    const backgroundBar = (actionStatus) => {
        if (actionStatus === "done") {
            return {
                background: `linear-gradient(#ffffff00 0%, #ffffff00 30%,  #0da64596 100%)`,
            };
        }
        if (actionStatus === "failed") {
            return {
                background: `linear-gradient(#ffffff00 0%, #ffffff00 30%,  #ca201599 100%)`,
            };
        }
        return {
            background: `initial`,
        };
    }


    const webcamRef = useRef(null)
    //const canvasRef = useRef(null)


    const videoConstraints = {
        // width: 640,
        // height: 480,
        facingMode: "user"
    };

    const imgHandler = (actionStatus) => {
        switch (actionStatus) {
            case "done":
                return images.check;
            case "failed":
                return images.close;
            default:
                return "";
        }
    }


    const imgClassHandler = (actionStatus) => {
        switch (actionStatus) {
            case "done":
                return classes.check;
            case "failed":
                return classes.close;
            default:
                return "";
        }
    }


   /*const capture = React.useCallback(
        () => {
            const frame2images = URL.createObjectURL(dataURItoBlob(webcamRef.current.getScreenshot()))
           /!*setAuth({
                ...auth,
                frame2: frame2images,
            });*!/
            fram2Ref.current = frame2images
            // frame1 = webcamRef.current.getScreenshot();
            // setSrc(imageSrc)
        },
        [webcamRef]
    );*/


    /*const capture = () => {
        const frame2images = URL.createObjectURL(dataURItoBlob(webcamRef.current.getScreenshot()))
        fram2Ref.current = frame2images
    }*/


    const submit = async () => {
        //capture()
        startTimer()
    }


    return (
        <Layout>
            {!white && <div className={`${classes.container}  column jc-around ai-center rounded-8 height-100`}>
                <div className={`${classes.header} width-100 position-absolute column jc-between ai-center py-4`}
                     style={backgroundBar(actionStatus)}
                >
                    {/*<span className={`fs-03 width-100 text-center px-2 font-weight-bold`} style={{lineHeight:"4.7vh"}}>{STEP[action]}</span>*/}
                    {!actionStep.includes("BEFORE") && !count && <span
                        className={`fs-03 width-90 text-center px-2 py-05 font-weight-bold rounded-8 ${classes.title}`}>{actionStep !== "BEFORE_START" && STEP[actionStep]}</span>}

                    <img
                        src={imgHandler(actionStatus)}
                        className={`${imgClassHandler(actionStatus)} ${classes.icon}`}

                        //alt={`${request.isVerified ? "Verified" : "UnVerified"}`}
                    />

                </div>
                <Webcam
                    //videoConstraints={videoConstraints}
                    mirrored={true}
                    audio={false}
                    screenshotFormat="image/jpeg"
                    // width="700px"
                    // height="10000px"
                    //width="100%"
                    height="100%"
                    ref={webcamRef}
                    videoConstraints={{
                        ...videoConstraints,
                        frameRate: {ideal: 3, max: 3},
                    }}
                    style={{
                        position: "absolute",
                        //transform: "scaleX(-1)",
                    }}

                />


                {/* <canvas ref={canvasRef} style={{
                    position: "absolute",
                    // transform: "scaleX(-1)",
                    height: "100%",
                    // marginLeft: "auto",
                    //marginRight: "auto",
                    // left: 0,
                    // right: 0,
                    //zIndex: 9,
                    // width: 640,
                    // height: 480
                }}/>*/}

                {!detectFace && <div
                    className={`${classes.fullWrapper} position-absolute width-100 flex jc-center ai-center direction-ltr fs-04 flashit`}>

                    {/*<span>Finding the face...</span>*/}
                    <span>Loading...</span>
                </div>}


                {count && <div className={`${classes.wrapper} width-100 flex jc-center ai-center position-absolute`}>
                    {/*<Countdown
                        date={ Date.now() + 5000 }
                        renderer={props => <div className={`fs-30 font-weight-bold text-white`}>{props.seconds}</div>}
                        onComplete={() => startLiveness(mediaResults , action)}
                />*/}

                    <span className={`fs-30 font-weight-bold text-white`}>{time}</span>

                </div>}

                {!count && <div className={`${classes.frame} position-absolute width-100`}
                                style={{backgroundImage: `url("${images.frame}")`}}/>}

                {!count && (actionStep === "BEFORE_START") && detectFace && <Button
                    buttonClass={`${classes.thisButton} cursor-pointer position-absolute`}
                    type="button"
                    buttonTitle="شروع"
                    //onClick={capture}
                    //onClick={()=>setCount(prevState => !prevState)}
                    onClick={() => submit()}
                    //onClick={()=>submit()}
                    //onClick={()=>startLiveness(mediaResults , action)}
                    //onDoubleClick={(e) => submit(e)}
                />}

                <MediaPipe
                    webcamRef={webcamRef}
                    setDetectFace={setDetectFace}
                    setActionStatus={setActionStatus}
                    reloadTimer={reloadTimer}
                    startTimer={startTimer}
                    actionStep={actionStep}
                    items={items}
                    time={time}
                    fram2Ref={fram2Ref}
                    count={setCount}
                    setActionStep={setActionStep}
                    setWhite={setWhite}
                />


            </div>}
        </Layout>
    );
};

export default Liveness;
