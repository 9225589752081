import React from 'react';
import classes from './UserVideoPreview.module.css'
import HeaderBuilder from "../../../components/HeaderBuilder/HeaderBuilder";
import Button from "../../../components/Button/Button";
import Layout from "../../../components/Layout/Layout";
import {useLocation, useNavigate} from "react-router-dom";
import * as RoutesName from "../../../routes/routes";
import useAuth from "../../../Hooks/useAuth";

const UserVideoPreview = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const {auth, setAuth} = useAuth();

    const videoData = location.state?.src || "";

   /* console.log("videoSrc" , videoSrc)*/


   /* const createBlob = (res) => {
        const blob =  new Blob([res]);
        return  URL.createObjectURL(blob)
    }
*/
    const prev = () => {
        navigate(RoutesName.UserVideo)
    }
    const next = () => {
        setAuth({
            ...auth,
            video: videoData,
        });
        navigate(RoutesName.UserSignatureHelp)
    }

    return (
        <Layout>
            <div className={`${classes.container} column jc-between ai-center height-100 py-3 px-2`}>
                <HeaderBuilder step="گام 4" title="برسی فیلم"/>
                <span>فیلم زیر از چهره شما ثبت و بعد تایید به رایانه مرکزی ارسال می‌شود.</span>




                <video controls className={`${classes.video} rounded-8 width-85`}>
                    <source src={videoData?.url} type="video/webm"/>
                </video>

                <div className={`${classes.header} width-100  column jc-start ai-center py-2 px-2 text-center`}>

                        <span>{videoData?.voiceCaptchaText}</span>
                    <span>{videoData?.voiceCaptchaParams.map( name =>

                        <span className="mx-05" key={name}
                              title={name}> {name} </span>

                    )}</span>




                </div>

                <div className={`row width-100 jc-center ai-center`}>
                    <Button
                        buttonClass={`${classes.thisButton} ${classes.prev} cursor-pointer ml-05`}
                        type="button"
                        buttonTitle="تکرار فیلم‌برداری"
                        onClick={() => prev()}
                    />
                    <Button
                        buttonClass={`${classes.thisButton} ${classes.next} cursor-pointer mr-05`}
                        type="button"
                        buttonTitle="تائید"
                        onClick={() => next()}
                    />
                </div>




            </div>
        </Layout>
    );
};

export default UserVideoPreview;
