import React, {useRef, useState} from 'react';
import classes from './Login.module.css'
import Layout from "../../../components/Layout/Layout";
import {useNavigate} from "react-router-dom";
import * as RoutesName from "../../../routes/routes";
import Button from "../../../components/Button/Button";
import {images} from "../../../assets/images/images";
import TextInput from "../../../components/TextInput/TextInput";
import {agahInquiry, captcha, localStatus, preRegister, register, start, verify} from "../../../api/auth";
import {createBlob2, getBase64, isValidNationalCode} from "../../../utils/utils";
import toast from "react-hot-toast";
import Loading from "../../../components/Loading/Loading";
import useAuth from "../../../Hooks/useAuth";
import Icon from "../../../components/Icon/Icon";
import Countdown from "react-countdown";
import {browserVersion, deviceType, fullBrowserVersion, getUA, osName} from "react-device-detect";


const Login = () => {

    const navigate = useNavigate();

    const {auth, setAuth} = useAuth();

    const[loading, setLoading]= useState(false)
    const[needCaptcha, setNeedCaptcha]= useState(false);
    const[captchaSrc, setCaptchaSrc]= useState(null);

    const[ratelimit, setRatelimit]= useState({
        status: false,
        time: 0,
    });

    const [loginInput, setLoginInput] = useState({
        nationalCode: {value: "", error: [], nameFa: "کد‌ملی"},
        sejamCode: {value: "", error: [], nameFa: "کد‌ پیگیری سجام"},
        captcha: {value: "", error: [], nameFa: "Captcha"},
    });


    let initialToken = null
    let reference = null
    let loginToken = null
    //let accessToken = null
    let accessToken = useRef(null)
    let nextStepToken = null
    let processId = auth.processId
    let captcha_processId = useRef(null)
    let refresh = false


    const captchaFunction = async (refresh) => {
        try {
            const url = `/kyc/process/v2/${captcha_processId.current}/agah_inquiry`;
            const captchaFunc = await captcha(captcha_processId.current, refresh, url, accessToken.current, )
            const blob = new Blob([captchaFunc.data]);
            setCaptchaSrc(URL.createObjectURL(blob))
        } catch (captchaFunc) {
            if (captchaFunc.response.status === 401) {
                toast.error(captchaFunc.response.data.message ? captchaFunc.response.data.message : "زمان مجاز استفاده از سیستم پایان رسید")
                //return false;
            }
            if (captchaFunc.response.status === 403) {
                toast.error(captchaFunc.response.data.message ? captchaFunc.response.data.message : "مجوز استفاده از این سرویس برای شما صادر نشده است")
                //return false;
            }
            else if (captchaFunc.response.status !== 200 ) {
                toast.error(captchaFunc.response.data.message ? captchaFunc.response.data.message : "در حال حاضز امکان استفاده از سرویس وجود ندارد لطفا دباره تلاش کنید!")
                //return false;
            }
        }
    }

/*

    console.log("deviceType", deviceType)
    console.log("fullBrowserVersion", fullBrowserVersion)
    console.log("osName", osName)
    console.log("browserVersion", browserVersion)
    console.log("getUA", getUA)

    const device = deviceType + "-" + osName + "-" +  browserVersion + "-"  + loginInput.nationalCode.value

    console.log("device", device)
    console.log("device type", typeof device)
*/


/*    const code = "0022410120"

    const code2 = "09" + code.slice(0,9)

    console.log("code" , code)
    console.log("code2" , code2)
    console.log("code2 type" , typeof code2)*/


    const getTokenFunc = async (newAuth) => {

        try {
            const preRegisterFunc = await preRegister()
            initialToken = preRegisterFunc.data.token



        } catch (preRegisterFunc) {
            /*if (preRegisterFunc.response.status === 400) {
                toast.error(preRegisterFunc.response.data.message ? preRegisterFunc.response.data.message : "خطا در سرور!")
            }
            if (preRegisterFunc.response.status === 500) {
                toast.error(preRegisterFunc.response.data.message ? preRegisterFunc.response.data.message : "خطا در سرور!")
            }
            if (preRegisterFunc.response.status === 404) {
                toast.error(preRegisterFunc.response.data.message ? preRegisterFunc.response.data.message : "خطا در سرور!")
            }
            if (preRegisterFunc.response.status === 'CORS error') {
                toast.error(preRegisterFunc.response.data.message ? preRegisterFunc.response.data.message : "خطا در سرور!")
            }
            else {
                toast.error("خطا در سرور!")
            }*/


            if (preRegisterFunc.response.status === 401) {
                toast.error(preRegisterFunc.response.data.message ? preRegisterFunc.response.data.message : "زمان مجاز استفاده از سیستم پایان رسید")
                //return false;
            }
            if (preRegisterFunc.response.status === 403) {
                toast.error(preRegisterFunc.response.data.message ? preRegisterFunc.response.data.message : "مجوز استفاده از این سرویس برای شما صادر نشده است")
                //return false;
            }
            if (preRegisterFunc.response.status === 429) {
                toast.error(preRegisterFunc.response.data.message ? preRegisterFunc.response.data.message : "در حال حاضز امکان استفاده از سرویس وجود ندارد لطفا دباره تلاش کنید!")
                setRatelimit({
                    status: true,
                    time: parseInt(preRegisterFunc?.response?.headers['x-ratelimit-reset']),
                })
            }
            else if (preRegisterFunc.response.status !== 200) {
                toast.error(preRegisterFunc.response.data.message ? preRegisterFunc.response.data.message : "در حال حاضز امکان استفاده از سرویس وجود ندارد لطفا دباره تلاش کنید!")
                //return false;
            }
        }
        const registerData = {
            appVersion: '1',
            caller: 'co.nilin.ekyc',
            deviceId: deviceType + "-" + osName + "-" +  browserVersion + "-"  + loginInput.nationalCode.value,
            mobile: "09" + loginInput.nationalCode.value.slice(0,9),
            os: 'Android',
            osVersion: '10',
        }
        try {
            const registerFunc = await register(initialToken, registerData)
            reference = registerFunc.data.reference

        } catch (registerFunc) {
            /*if (registerFunc && registerFunc.response.status === 400) {
                toast.error(registerFunc.response.data.message ? registerFunc.response.data.message : "خطا در سرور!")
            }
            if (registerFunc && registerFunc.response.status === 401 ) {
                toast.error(registerFunc.response.data.message ? registerFunc.response.data.message : "خطا در سرور!")
            }
            if (registerFunc && registerFunc.response.status === 404 ) {
                toast.error(registerFunc.response.data.message ? registerFunc.response.data.message : "خطا در سرور!")
            }
            else {
                toast.error("خطا در سرور!")
            }*/

            if (registerFunc.response.status === 401) {
                toast.error(registerFunc.response.data.message ? registerFunc.response.data.message : "زمان مجاز استفاده از سیستم پایان رسید")
                //return false;
            }
            if (registerFunc.response.status === 403) {
                toast.error(registerFunc.response.data.message ? registerFunc.response.data.message : "مجوز استفاده از این سرویس برای شما صادر نشده است")
                //return false;
            }
            if (registerFunc.response.status === 429) {
                toast.error(registerFunc.response.data.message ? registerFunc.response.data.message : "در حال حاضز امکان استفاده از سرویس وجود ندارد لطفا دباره تلاش کنید!")
                setRatelimit({
                    status: true,
                    time: parseInt(registerFunc?.response?.headers['x-ratelimit-reset']),
                })
            }
            else if (registerFunc.response.status !== 200) {
                toast.error(registerFunc.response.data.message ? registerFunc.response.data.message : "در حال حاضز امکان استفاده از سرویس وجود ندارد لطفا دباره تلاش کنید!")
                //return false;
            }
        }
        const verifyData = {
            reference: reference,
            otpCode: '',
        }
        try {
            const verifyFunc = await verify(initialToken, verifyData)
            loginToken = verifyFunc.data.loginToken

            newAuth = {
                ...newAuth,
                loginToken: verifyFunc.data.loginToken
            }

        } catch (verifyFunc) {
            if (verifyFunc.response.status === 401) {
                toast.error(verifyFunc.response.data.message ? verifyFunc.response.data.message : "زمان مجاز استفاده از سیستم پایان رسید")
                //return false;
            }
            if (verifyFunc.response.status === 403) {
                toast.error(verifyFunc.response.data.message ? verifyFunc.response.data.message : "مجوز استفاده از این سرویس برای شما صادر نشده است")
                //return false;
            }
            if (verifyFunc.response.status === 429) {
                toast.error(verifyFunc.response.data.message ? verifyFunc.response.data.message : "در حال حاضز امکان استفاده از سرویس وجود ندارد لطفا دباره تلاش کنید!")
                setRatelimit({
                    status: true,
                    time: parseInt(verifyFunc?.response?.headers['x-ratelimit-reset']),
                })
            }
            else if (verifyFunc.response.status !== 200) {
                toast.error(verifyFunc.response.data.message ? verifyFunc.response.data.message : "در حال حاضز امکان استفاده از سرویس وجود ندارد لطفا دباره تلاش کنید!")
                //return false;
            }
        }
        const localStatusData = {
            nationalCode: loginInput.nationalCode.value,
            sejamCode: loginInput.sejamCode.value,
        }
        try {
            const localStatusFunc = await localStatus(loginToken, localStatusData)

            newAuth = {
                ...newAuth,
                processStatus: localStatusFunc.data.processStatus
            }

            if (localStatusFunc.data.process !== null) {
                navigate(RoutesName.Result)
            } else {
                const startData = {
                    mobile: '',
                    nationalCode: loginInput.nationalCode.value,
                    deviceId: '',
                    osVersion: '',
                    appVersion: '',
                    caller: '',
                    os: 'ANDROID',
                }
                try {
                    const startFunc = await start(loginToken, startData)
                    accessToken.current = startFunc.data.accessToken
                    nextStepToken = startFunc.data.nextStepToken

                    captcha_processId.current = startFunc.data.processId

                    newAuth = {
                        ...newAuth,
                        processId: startFunc.data.processId
                    }

                    const agahInquiryData = {
                        requestId: auth.requestId,
                        nationalCode: loginInput.nationalCode.value,
                        sejamCode: loginInput.sejamCode.value,
                    }
                    try {
                        const agahInquiryFunc = await agahInquiry(accessToken.current, startFunc.data.processId, nextStepToken, agahInquiryData, `${needCaptcha && loginInput.captcha.value}`)
                        const nextStepData = {
                            nextStepToken: agahInquiryFunc.data.nextStepToken,
                            otpFollowupCode: agahInquiryFunc.data.otpFollowupCode,
                            accessToken: accessToken.current,
                            processId: startFunc.data.processId,
                            nextStepTokenPrev: nextStepToken,
                        }

                        newAuth = {
                            ...newAuth,
                            requestId: auth.requestId + 1
                        }

                        navigate(RoutesName.OTP, {state: {res: nextStepData && nextStepData}})
                    } catch (agahInquiryFunc) {
                        newAuth = {
                            ...newAuth,
                            requestId: auth.requestId + 1
                        }
                        setLoading(false)


                        setNeedCaptcha(true)
                        // here

                        captchaFunction((refresh = false))


                        if (agahInquiryFunc.response.status === 401) {
                            toast.error(agahInquiryFunc.response.data.message ? agahInquiryFunc.response.data.message : "زمان مجاز استفاده از سیستم پایان رسید")
                            //return false;
                        }
                        if (agahInquiryFunc.response.status === 403) {
                            toast.error(agahInquiryFunc.response.data.message ? agahInquiryFunc.response.data.message : "مجوز استفاده از این سرویس برای شما صادر نشده است")
                            //return false;
                        }

                        if (agahInquiryFunc.response.status === 429) {
                            toast.error(agahInquiryFunc.response.data.message ? agahInquiryFunc.response.data.message : "در حال حاضز امکان استفاده از سرویس وجود ندارد لطفا دباره تلاش کنید!")
                            setRatelimit({
                                status: true,
                                time: parseInt(agahInquiryFunc?.response?.headers['x-ratelimit-reset']),
                            })
                        }

                        else if (agahInquiryFunc.response.status !== 200) {
                            toast.error(agahInquiryFunc.response.data.message ? agahInquiryFunc.response.data.message : "در حال حاضز امکان استفاده از سرویس وجود ندارد لطفا دباره تلاش کنید!")
                            //return false;
                        }
                    }

                } catch (startFunc) {
                    if (startFunc.response.status === 401) {
                        toast.error(startFunc.response.data.message ? startFunc.response.data.message : "زمان مجاز استفاده از سیستم پایان رسید")
                        //return false;
                    }
                    if (startFunc.response.status === 403) {
                        toast.error(startFunc.response.data.message ? startFunc.response.data.message : "مجوز استفاده از این سرویس برای شما صادر نشده است")
                        //return false;
                    }
                    if (startFunc.response.status === 429) {
                        toast.error(startFunc.response.data.message ? startFunc.response.data.message : "در حال حاضز امکان استفاده از سرویس وجود ندارد لطفا دباره تلاش کنید!")
                        setRatelimit({
                            status: true,
                            time: parseInt(startFunc?.response?.headers['x-ratelimit-reset']),
                        })
                    }
                    else if (startFunc.response.status !== 200) {
                        toast.error(startFunc.response.data.message ? startFunc.response.data.message : "در حال حاضز امکان استفاده از سرویس وجود ندارد لطفا دباره تلاش کنید!")
                        //return false;
                    }
                }
            }
        } catch (localStatusFunc) {


            if (localStatusFunc.response.status === 401) {
                toast.error(localStatusFunc.response.data.message ? localStatusFunc.response.data.message : "زمان مجاز استفاده از سیستم پایان رسید")
                //return false;
            }
            if (localStatusFunc.response.status === 403) {
                toast.error(localStatusFunc.response.data.message ? localStatusFunc.response.data.message : "مجوز استفاده از این سرویس برای شما صادر نشده است")
                //return false;
            }
            if (localStatusFunc.response.status === 429) {
                toast.error(localStatusFunc.response.data.message ? localStatusFunc.response.data.message : "در حال حاضز امکان استفاده از سرویس وجود ندارد لطفا دباره تلاش کنید!")
                setRatelimit({
                    status: true,
                    time: parseInt(localStatusFunc?.response?.headers['x-ratelimit-reset']),
                })
            }
            else if (localStatusFunc.response.status !== 200) {
                toast.error(localStatusFunc.response.data.message ? localStatusFunc.response.data.message : "در حال حاضز امکان استفاده از سرویس وجود ندارد لطفا دباره تلاش کنید!")
                //return false;
            }
        }

        setAuth(newAuth)
    }



    const inputHandler = (e) => {
        // setloginInputStatus(null)
        let errorMessage = []
        let inputVal = e.target.value
        if (typeof e.target.dataset?.min && inputVal.length < e.target.dataset.min) {
            errorMessage.push(`${e.target.dataset.namefa} حداقل باید ${e.target.dataset.min} کاراکتر باشد.`)
        }
        if (typeof e.target.dataset?.max && inputVal.length >= e.target.dataset.max) {
            errorMessage.push(`${e.target.dataset.namefa} حداکثر باید ${e.target.dataset.max - 1} کاراکتر باشد.`)
        }

        if (e.target.dataset?.name === "nationalCode" && !isValidNationalCode(inputVal)) {
            inputVal = inputVal.replace(/[^0-9]+/g, "")
            errorMessage.push("کد ملی صحیح نیست!")
        }

        if (e.target.dataset?.name === "sejamCode") {
            inputVal = inputVal.replace(/[^0-9]+/g, "")
        }

        let prevState = {
            ...loginInput,
            [e.target.dataset.name]: {...loginInput[e.target.dataset.name], value: inputVal, error: errorMessage}
        }

        setLoginInput(prevState)
    }

    const isFormValid = () => {
        let inputs = {...loginInput}

        const hasError = Object.values(loginInput).find(input => input.error.length > 0)
        if (hasError) return false
        let isEmpty = false
        for (const key in inputs) {

            if (key === 'captcha' && !needCaptcha) {
                continue;
            }

            if (inputs[key].value.length === 0) {
                isEmpty = true
                inputs = {
                    ...inputs,
                    [key]: {
                        ...inputs[key],
                        error: [`وارد کردن فیلد ${inputs[key].nameFa} الزامی است.`]
                    }
                }
            }
        }
        setLoginInput(inputs);
        return !isEmpty;
    }


    const submit = async (e) => {
        e.preventDefault();

        let newAuth = {...auth}

        if (!isFormValid()) return false;
        setLoading(true)

        newAuth = {
            ...newAuth,
            isLogin: true,
            nationalCode: loginInput.nationalCode.value,
            sejamCode: loginInput.sejamCode.value,
        }


        getTokenFunc(newAuth).then(()=>setLoading(false))
    }

    const buttonTitleHandler = () => {
      return <div className={`row jc-center ai-center ${ratelimit.status && 'ml-025'}`}>
          {ratelimit.status &&
            <span className={`mr-025`}>
                <Countdown
                  onStart={()=>ratelimit.status}
                  date={ Date.now() + ratelimit.time*1000 }
                  renderer={props => <div className={` direction-ltr`}> ({props.minutes}:{props.seconds}) </div>}
                  onComplete={() => setRatelimit({
                      status: false,
                      time: 0,
                  })}
                />
            </span>
          }
          <span>ثبت</span>
      </div>
    }


    return (
        <Layout>
            <form onSubmit={(e) => submit(e)}
                  className={`${classes.container} column jc-around ai-center height-100 py-2 ${loading && 'blur-2'}`}>

                <div className={`column jc-center ai-center`}>
                    <img src={images.shenasLogo} alt="shenasLogo" className={`${classes.logo} mb-1`}/>

                    <span className={`font-weight-bold fs-04 text-light-blue mt-1`}>الترا شناس</span>
                </div>


                <span className={`fs-01 font-weight-bold`}>احراز هویت غیر حضوری سجام</span>

                <div className={`column width-75`}>
                    <TextInput
                        value={loginInput.nationalCode.value}
                        type="text"
                        label="کد‌ملی"
                        data-name="nationalCode"
                        data-namefa="کد‌ملی"
                        data-type="input"
                        //data-min={10}
                        maxLength="10"
                        onchange={(e) => inputHandler(e)}
                        alerts={loginInput.nationalCode.error}
                        inputClass={`width-100 my-2`}
                    />

                    <TextInput
                        value={loginInput.sejamCode.value}
                        type="text"
                        label="کد‌ پیگیری سجام"
                        data-name="sejamCode"
                        data-namefa="کد‌ پیگیری سجام"
                        data-type="input"
                        data-min={10}
                        maxLength="10"
                        onchange={(e) => inputHandler(e)}
                        alerts={loginInput.sejamCode.error}
                        inputClass={`width-100 my-2`}

                    />

                    {needCaptcha &&

                        <div className={`column jc-center ai-center mb-1 mt-2`}>

                            <div className={`row jc-center ai-center mb-1`}>
                                 <img src={captchaSrc} alt="" className={`${classes.captcha}  ml-05`}/>
                                <Icon
                                    iconName="icon-arrows-ccw flex fs-07"
                                    iconClass={`${classes.thisIcon} hover-text cursor-pointer mr-05`}
                                    onClick={()=>captchaFunction(refresh = true)}
                                />
                            </div>

                            <TextInput
                                value={loginInput.captcha.value}
                                type="text"
                                label="Captcha"
                                data-name="captcha"
                                data-namefa="Captcha"
                                data-type="input"
                                data-min={6}
                                maxLength="6"
                                onchange={(e) => inputHandler(e)}
                                alerts={loginInput.captcha.error}
                                inputClass={`width-100 mt-1`}

                            />
                    </div>


                    }
                </div>

                <span className={`fs-0-9 text-gray cursor-pointer hover-text`} onClick={()=>window.open('https://profilesejam.csdiran.ir/RecoveryTraceCode')}>بازیابی کد پیگیری سجام</span>


                <Button
                    buttonClass={`${classes.thisButton} cursor-pointer`}
                    type="submit"
                    buttonTitle={buttonTitleHandler()}
                    disabled={ratelimit.status}
                />


            </form>
            {loading && <Loading/>}
        </Layout>
    );
};

export default Login;
