import React from 'react';
import classes from './UserSignaturePreview.module.css';
import {useLocation, useNavigate} from "react-router-dom";
import * as RoutesName from "../../../routes/routes";
import HeaderBuilder from "../../../components/HeaderBuilder/HeaderBuilder";
import {images} from "../../../assets/images/images";
import Button from "../../../components/Button/Button";
import Layout from "../../../components/Layout/Layout";
import useAuth from "../../../Hooks/useAuth";
import {dataURItoBlob} from "../../../utils/generateMD5";

const UserSignaturePreview = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const {auth, setAuth} = useAuth();

    const imageSrc = location.state?.src || "";

    const prev = () => {
        navigate(RoutesName.UserSignature)
    }
    const next = () => {
        setAuth({
            ...auth,
            signature: URL.createObjectURL(dataURItoBlob(imageSrc)),
            //signature: imageSrc,
        });
        navigate(RoutesName.UploadFiles)
    }

    return (
        <Layout>
            <div className={`${classes.container} column jc-between ai-center height-100 py-3 px-2`}>
                <HeaderBuilder step="گام 6" title="تائید عکس امضا"/>
                <span>عکس زیر از امضا شما برداشته شده و بعد از تایید به رایانه مرکزی ارسال خواهد شد. در صورت تمایل، می‌توانید نسبت به تکرار فرایند عکس‌برداری اقدام کنید.</span>





                <img src={imageSrc ? imageSrc : images.shenasLogo} className={`${classes.image} rounded-8`}
                     alt=""/>
                <div className={`row width-100 jc-center ai-center`}>
                    <Button
                        buttonClass={`${classes.thisButton} ${classes.prev} cursor-pointer ml-05`}
                        type="button"
                        buttonTitle="تکرار عکس‌برداری"
                        onClick={() => prev()}
                    />
                    <Button
                        buttonClass={`${classes.thisButton} ${classes.next} cursor-pointer mr-05`}
                        type="button"
                        buttonTitle="تائید"
                        onClick={() => next()}
                    />
                </div>




            </div>
        </Layout>
    );
};

export default UserSignaturePreview;
