import shenasLogo from "./src/shenas.svg";
import frame from "./src/farme.svg";
import check from "./src/Check.svg";
import close from "./src/Close.svg";
import SignatureFrame from "./src/SignatureFrame.svg";
import uploadFile from "./src/uploadFile.gif";
import uploadFile2 from "./src/uploadFile2.gif";
import crloading from "./src/crloading.gif";
import checkAnimation from "./src/check.gif";
import crossAnimation from "./src/cross.gif";
import id_card_serial from "./src/id_card_serial.png";
import id_card_receipt from "./src/id_card_receipt.png";


export const images = {
  shenasLogo: shenasLogo,
  frame: frame,
  check: check,
  close: close,
  SignatureFrame: SignatureFrame,
  uploadFile: uploadFile,
  uploadFile2: uploadFile2,
  crloading: crloading,
  checkAnimation: checkAnimation,
  crossAnimation: crossAnimation,
  id_card_serial: id_card_serial,
  id_card_receipt: id_card_receipt,
};
