import axios from "./axios";


export const preRegister = () => {
    const params = new URLSearchParams();
    //params.append('timestamp', Date.now().toString());
    return axios.get(`/kyc/sdk/v1/verify`, {
        data: params,
        headers: {
            'key': '60948de6-1d84-4664-86d6-6149f42851b9',
            'Accept': 'application/json',
            //'User-Agent': 'android-sejam-1.0',
            'Version': '200',
        }
    });
}

export const register = (initialToken, registerData) => {
    return axios.post(`/kyc/profile/v1/register`,registerData, {
        headers: {
            'Authorization': `Bearer ${initialToken} `,
            'Accept': 'application/json',
            //'User-Agent': 'android-sejam-1.0',
            'Version': '200',
        }
    });
}

export const verify = (initialToken, verifyData) => {
    return axios.post(`/kyc/profile/v1/verify`,verifyData, {
        headers: {
            'Authorization': `Bearer ${initialToken} `,
            'Accept': 'application/json',
            //'User-Agent': 'android-sejam-1.0',
            'Version': '200',
        }
    });
}

export const localStatus = (loginToken, localStatusData) => {
    return axios.post(`/kyc/inquiry/v1/fetch_local_status`,localStatusData, {
        headers: {
            'Authorization': `Bearer ${loginToken} `,
            'Accept': 'application/json',
            //'User-Agent': 'android-sejam-1.0',
            'Version': '200',
        }
    });
}

export const start = (loginToken, startData) => {
    return axios.post(`/kyc/process/v1/start`,startData, {
        headers: {
            'Authorization': `Bearer ${loginToken} `,
            'Accept': 'application/json',
            //'User-Agent': 'android-sejam-1.0',
            'Version': '200',
        }
    });
}

export const agahInquiry = (accessToken, processId, nextStepToken, startData, CaptchaText = '') => {
    return axios.post(`/kyc/process/v2/${processId}/agah_inquiry`,startData, {
        headers: {
            'Authorization': `Bearer ${accessToken} `,
            'processId': processId,
            'Step-Token': nextStepToken,
            'CaptchaText': CaptchaText,
            //'User-Agent': 'android-sejam-1.0',
            'Version': '200',
        }
    });
}

export const validateOtp = (accessToken, processId, nextStepToken, validateOtpData, CaptchaText = '') => {
    return axios.post(`/kyc/process/v2/${processId}/validate_otp`,validateOtpData, {
        headers: {
            'Authorization': `Bearer ${accessToken} `,
            'processId': processId,
            'Step-Token': nextStepToken,
            'CaptchaText': CaptchaText,
            //'User-Agent': 'android-sejam-1.0',
            'Version': '200',
        }
    });
}

export const completeOtp = (accessToken, processId, nextStepToken, completeOtpData, CaptchaText = '') => {
    return axios.post(`/kyc/process/v2/${processId}/complete_otp`,completeOtpData, {
        headers: {
            'Authorization': `Bearer ${accessToken} `,
            'processId': processId,
            'Step-Token': nextStepToken,
            'CaptchaText': CaptchaText,
            //'User-Agent': 'android-sejam-1.0',
            'Version': '200',
        }
    });
}

export const requestPayment = (processId, completeOtp_accessToken, completeOtp_nextStepToken) => {
    return axios.post(`/kyc/process/v1/${processId}/request_payment`, null, {
        headers: {
            'Authorization': `Bearer ${completeOtp_accessToken} `,
            'Step-Token': completeOtp_nextStepToken,
            //'User-Agent': 'android-sejam-1.0',
            'Version': '200',
        }
    });
}

export const captcha = (processId, refresh, url, accessToken) => {
/*    const params = new URLSearchParams();
    params.append('refresh', refresh);
    params.append('url', url);*/
    return axios.get(`/captcha?refresh=${refresh}&url=${url}`, {
        /*data: params,*/
        headers: {
            'Authorization': `Bearer ${accessToken} `,
            'processId': processId,
            'Version': '200',
        },
        responseType: "arraybuffer"

    });
}


export const submit = (selfiFile, frame1File, frame2File, processId, completeOtp_kycFollowupCode, requestId, completeOtp_accessToken, nextStepToken) => {
    const data = new FormData();
    data.append('face1', selfiFile, 'face1.jpg');
    data.append('face2', frame1File, 'face2.jpg');
    data.append('face3', frame2File, 'face3.jpg');
    return axios.post(`/kyc/service/v1/${processId}/submit?kyc_followup_code=${completeOtp_kycFollowupCode}&request_id=${requestId}`,data, {
            headers: {
                'Authorization': `Bearer ${completeOtp_accessToken} `,
                'step-token': nextStepToken,
                'Version': '200',
                'Content-Type': 'multipart/form-data',
                'Device': 'a1583707b81e77f8',
                'Timestamp': Date.now(),
                'Checksum': 'e707e6f4527d7ae3bae82336e76dbe81',
            }
        }
        );
}


export const query = (processId, completeOtp_kycFollowupCode, requestId, completeOtp_accessToken, nextStepToken) => {
    return axios.get(`/kyc/service/v1/${processId}/query?kyc_followup_code=${completeOtp_kycFollowupCode}&request_id=${requestId}`, {
        headers: {
            'Authorization': `Bearer ${completeOtp_accessToken} `,
            'Step-Token': nextStepToken,
            //'User-Agent': 'android-sejam-1.0',
            'Version': '200',
        }
    });
}


export const uploadVideo = (videoText, signFile, videoFile, processId, requestId, completeOtp_accessToken, nextStepToken) => {
    const data = new FormData();
    data.append('videoText', videoText);
    data.append('sign', signFile, 'sign.jpg');
    data.append('video', videoFile, 'video.mp4');
    return axios.post(`/kyc/storage/v1/${processId}/upload?request_id=${requestId}`,data, {
            headers: {
                'Authorization': `Bearer ${completeOtp_accessToken} `,
                'step-token': nextStepToken,
                'Version': '200',
                'Content-Type': 'multipart/form-data',
     /*           'Device': 'a1583707b81e77f8',
                'Timestamp': Date.now(),
                'Checksum': 'e707e6f4527d7ae3bae82336e76dbe81',*/
            }
        }
        );
}

export const sign = (accessToken, processId, nextStepToken, signData) => {
    return axios.post(`/kyc/process/v1/${processId}/sign`,signData, {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Step-Token': nextStepToken,
            //'User-Agent': 'android-sejam-1.0',
            'Version': '200',
        }
    });
}

